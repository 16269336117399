<template>
<svg width="12px" height="16px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="actions/icons/empty/bookmark" transform="translate(-6.000000, -4.000000)" fill="#000000" fill-rule="nonzero">
            <path d="M18,19.5729239 L18,5.27856518 C18,4.57243313 17.4074348,4 16.6764698,4 L7.32353016,4 C6.59256517,4 6,4.57243313 6,5.27856518 L6,19.5729239 C5.9996799,19.7218777 6.07988388,19.8601852 6.21154257,19.9377164 C6.34320126,20.0152475 6.50720829,20.0207508 6.6441185,19.9522316 L12,17.2864232 L17.3558815,19.9522316 C17.4927917,20.0207508 17.6567987,20.0152475 17.7884574,19.9377164 C17.9201161,19.8601852 18.0003201,19.7218777 18,19.5729239 L18,19.5729239 Z M17.1176462,18.8739749 L12.2029411,16.4276536 C12.0762734,16.3646968 11.9259325,16.3646968 11.7992647,16.4276536 L6.88235376,18.8739749 L6.88235376,5.27856518 C6.88235376,5.04318783 7.07987516,4.85237679 7.32353016,4.85237679 L16.6764698,4.85237679 C16.9201248,4.85237679 17.1176462,5.04318783 17.1176462,5.27856518 L17.1176462,18.8739749 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "favoriteNot"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>