
<template>
  <svg
    width="14px"
    height="10px"
    viewBox="0 0 14 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
    <title>live</title>
    <desc>Created with Sketch.</desc>
    <g id="Export-for-Testing" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="live" transform="translate(0.000000, -2.000000)">
        <g id="noun_live_2146857_000000">
          <!--<rect id="Rectangle" x="0" y="0" width="14" height="14" />-->
          <g
            id="Group-6"
            transform="translate(0.700000, 2.800000)"
            fill="none"
            fill-rule="nonzero"
          >
            <path
              d="M10.7723355,0.153273703 C10.5482039,-0.0510912343 10.1922303,-0.0510912343 9.96809868,0.153273703 C9.74396711,0.35763864 9.74396711,0.682218247 9.96809868,0.886583184 C11.9720987,2.71384615 11.9720987,5.68314848 9.96809868,7.51041145 C9.74396711,7.71477639 9.74396711,8.03935599 9.96809868,8.24372093 C10.0735724,8.33989267 10.2185987,8.4 10.363625,8.4 C10.5086513,8.4 10.6536776,8.35191413 10.7591513,8.24372093 C13.2114144,6.00772809 13.2114144,2.37724508 10.7723355,0.153273703 Z"
              id="Path"
            />
            <path
              d="M8.70405186,2.25099698 C8.4726094,2.04966767 8.10502431,2.04966767 7.87358185,2.25099698 C7.64213938,2.45232628 7.64213938,2.77208459 7.87358185,2.9734139 C8.86742301,3.83794562 8.88103728,5.25909366 7.87358185,6.12362538 C7.64213938,6.32495468 7.64213938,6.64471299 7.87358185,6.8460423 C7.98249595,6.9407855 8.13225284,7 8.28200972,7 C8.43176661,7 8.5815235,6.9526284 8.6904376,6.8460423 C10.1743922,5.57885196 10.160778,3.51818731 8.70405186,2.25099698 Z"
              id="Path"
            />
            <path
              d="M2.632,0.887853816 C2.856,0.683195987 2.856,0.3581512 2.632,0.153493372 C2.408,-0.0511644572 2.05223529,-0.0511644572 1.82823529,0.153493372 C-0.609411765,2.3806521 -0.609411765,6.01633823 1.82823529,8.24349695 C1.93364706,8.33980652 2.07858824,8.4 2.22352941,8.4 C2.36847059,8.4 2.51341176,8.35184522 2.61882353,8.24349695 C2.84282353,8.03883913 2.84282353,7.71379434 2.61882353,7.50913651 C0.629176471,5.69129344 0.629176471,2.71773558 2.632,0.887853816 Z"
              id="Path"
            />
            <path
              d="M4.72613636,2.9734139 C4.95795455,2.77208459 4.95795455,2.45232628 4.72613636,2.25099698 C4.49431818,2.04966767 4.12613636,2.04966767 3.89431818,2.25099698 C2.43522727,3.51818731 2.43522727,5.57885196 3.89431818,6.8460423 C4.00340909,6.9407855 4.15340909,7 4.30340909,7 C4.45340909,7 4.60340909,6.9526284 4.7125,6.8460423 C4.94431818,6.64471299 4.94431818,6.32495468 4.7125,6.12362538 C3.73068182,5.25909366 3.73068182,3.83794562 4.72613636,2.9734139 Z"
              id="Path"
            />
            <circle id="Oval" cx="6.3" cy="4.48" r="1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "live"
};
</script>

<style scoped>
g {
  fill: currentColor !important;
}
</style>