import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "./translations/en.json";
import ug from "./translations/ug.json";
import ug_latin from "./translations/ug_latin.json";
import ug_cyrillic from "./translations/ug_cyrillic.json";
import zh_CN from "./translations/zh_CN.json";
import zh_HK from "./translations/zh_HK.json";
import my from "./translations/my.json";
import lo from "./translations/lo.json";
import ko from "./translations/ko.json";
import km from "./translations/km.json";
import vi from "./translations/vi.json";
import bo from "./translations/bo.json";

Vue.use(VueI18n);

export default new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    silentFallbackWarn: true,
    messages: {
        en: en,
        ug: ug,
        ug_la: ug_latin,
        ug_cy: ug_cyrillic,
        zh_CN: zh_CN,
        zh_HK: zh_HK,
        my: my,
        lo: lo,
        ko: ko,
        km: km,
        vi: vi,
        bo: bo,
    },
});
