<template>

<svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Audio-Cambodia2" transform="translate(-25.000000, -543.000000)">
            <rect fill="#FFFFFF" x="0" y="0" width="360" height="1001"></rect>
            <g id="Group-12" transform="translate(24.000000, 539.000000)" fill="#1B9739">
                <g id="Group-11">
                    <g id="Group-3">
                        <g id="media/Playing" transform="translate(1.000000, 4.000000)">
                            <g id="Group-6">
                                <path d="M6.5,0 C6.22857143,0 6,0.284444444 6,0.622222222 L6,7.37777778 C6,7.71555556 6.22857143,8 6.5,8 C6.77142857,8 7,7.71555556 7,7.37777778 L7,0.622222222 C7,0.284444444 6.78571429,0 6.5,0 Z" id="Path" fill-rule="nonzero"></path>
                                <path d="M4.5,0 C4.22857143,0 4,0.261818182 4,0.572727273 L4,8.42727273 C4,8.73818182 4.22857143,9 4.5,9 C4.77142857,9 5,8.73818182 5,8.42727273 L5,0.572727273 C5,0.261818182 4.77142857,0 4.5,0 Z" id="Path" fill-rule="nonzero"></path>
                                <path d="M2.5,1 C2.22857143,1 2,1.30967742 2,1.67741935 L2,6.32258065 C2,6.69032258 2.22857143,7 2.5,7 C2.77142857,7 3,6.69032258 3,6.32258065 L3,1.67741935 C3,1.30967742 2.77142857,1 2.5,1 Z" id="Path" fill-rule="nonzero"></path>
                                <path d="M0.5,2 C0.228571429,2 0,2.3047619 0,2.66666667 L0,5.33333333 C0,5.6952381 0.228571429,6 0.5,6 C0.771428571,6 1,5.6952381 1,5.33333333 L1,2.66666667 C1,2.3047619 0.785714286,2 0.5,2 Z" id="Path" fill-rule="nonzero"></path>
                                <path d="M8.5,2 C8.22857143,2 8,2.3047619 8,2.66666667 L8,5.33333333 C8,5.6952381 8.22857143,6 8.5,6 C8.77142857,6 9,5.6952381 9,5.33333333 L9,2.66666667 C9,2.3047619 8.77142857,2 8.5,2 Z" id="Path" fill-rule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "typeAudioPlaying"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>