<template>
<svg width="18px" height="22px" viewBox="0 0 18 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
    <title>Saved</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="nav/icons/unselected/saved" transform="translate(-3.000000, -1.000000)">
            <g id="Saved">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M15,5 C16.05,5 16.9177686,5.82004132 16.9944872,6.85130541 L17,7 L17,23 L10,20 L3,23 L3,7 C3,5.95 3.82004132,5.0822314 4.85130541,5.00551277 L5,5 L15,5 Z M5,7 L5,19.967034 L10,17.8240648 L15.0002615,19.967034 L15.0013731,7.07409802 L14.9983578,7.00175799 L5,7 Z M19,1 C20.05,1 20.9177686,1.82004132 20.9944872,2.85130541 L21,3 L21,19 L19,18 L19,5 C19,3.95 18.1799587,3.0822314 17.1486946,3.00551277 L17,3 L7,3 C7,1.95 7.81092975,1.0822314 8.84136551,1.00551277 L8.99,1 L19,1 Z" id="Path-2" fill="#949494" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "favorites"
};
</script>

<style scoped>
path {
    fill: currentColor !important;
}

</style>