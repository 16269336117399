
<template>
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Radio</title>
    <g id="nav/icons/unselected/radio" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun_Radio_2177110" transform="translate(2.000000, 2.000000)" fill="#DADADA" fill-rule="nonzero">
            <path d="M18.26875,5.03328588 L12.840625,5.03328588 L15.688125,2.77598129 L14.984375,1.83377398 L10.948125,5.03328588 L9.05125,5.03328588 L3.3325,0.5 L2.629375,1.44285133 L7.159375,5.03328588 L1.73125,5.03328588 C0.775625,5.03328588 0,5.83187581 0,6.81723273 L0,17.7160531 C0,18.700766 0.775,19.5 1.73125,19.5 L18.26875,19.5 C19.224375,19.5 20,18.7014101 20,17.7160531 L20,6.81723273 C20,5.83251983 19.225,5.03328588 18.26875,5.03328588 Z M3.15375,17.6006711 L2,17.6006711 L2,12.5 L3.15375,12.5 L3.15375,17.6006711 Z M6.15375,17.6006711 L5,17.6006711 L5,12.5 L6.15375,12.5 L6.15375,17.6006711 Z M9.15375,17.6006711 L8,17.6006711 L8,12.5 L9.15375,12.5 L9.15375,17.6006711 Z M15.5,17.5 C14.1196959,17.5 13,16.3807801 13,15.0003066 C13,13.6192199 14.1190827,12.5 15.5,12.5 C16.8809173,12.5 18,13.6192199 18,15.0003066 C17.9993868,16.3801668 16.8803041,17.5 15.5,17.5 Z M17.884375,9.78940411 L2.115625,9.78940411 L2.115625,7.01494814 L17.884375,7.01494814 L17.884375,9.78940411 L17.884375,9.78940411 Z" id="Shape"></path>
        </g>
    </g>
</svg>


</template>

<script>
export default {
  name: "radio"
};
</script>

<style scoped>
g {
  fill: currentColor !important;
}
</style>