<template>
  <svg
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="m.41,10c-.22,0-.4-.18-.41-.41v-2.33c0-.23.18-.41.41-.41s.41.18.41.41v1.92h8.37v-1.92c0-.23.18-.41.41-.41s.41.18.41.41v2.33c0,.23-.18.41-.41.41H.41Zm4.75-3s.09-.05.13-.09l2.16-2.19c.15-.16.15-.41,0-.57-.08-.08-.18-.12-.29-.12s-.2.04-.27.11l-1.47,1.49V.41c0-.23-.18-.41-.4-.41-.23,0-.41.18-.41.41v5.21l-1.47-1.49c-.08-.07-.17-.11-.28-.11-.11,0-.21.04-.29.12-.16.16-.16.41-.01.57l2.16,2.19s.08.07.13.09c.05.02.1.03.16.03s.11-.01.16-.03Z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "download",
  }
</script>

<style scoped>
  g {
    fill: currentColor !important;
  }
</style>
