<template>
    <v-container fluid ma-0 pa-0>
        <v-layout text-right wrap align-center>
            <v-row no-gutters no-wrap justify="end">
                <v-btn
                    v-if="showShare"
                    :ripple="false"
                    right
                    icon
                    class="medium-button"
                    :style="btnColor"
                    @click.stop="share"
                >
                    <v-icon>$vuetify.icons.share</v-icon>
                </v-btn>
                <v-btn
                    v-if="showShareFacebook"
                    :ripple="false"
                    right
                    icon
                    class="medium-button"
                    :style="btnColor"
                    @click.stop="shareFacebook"
                >
                    <v-icon>$vuetify.icons.facebook</v-icon>
                </v-btn>
                <v-btn
                    v-if="showDownload"
                    :ripple="false"
                    icon
                    class="medium-button"
                    :style="btnColor"
                    @click.stop="download"
                >
                    <v-icon>$vuetify.icons.download</v-icon>
                </v-btn>
                <ItemFavoriteButton
                    v-if="showFavorite"
                    class="ma-0 pa-0 medium-button"
                    :item="item"
                    :style="btnColor" 
                    :mediaType="mediaType"
                />
            </v-row>
        </v-layout>

        <v-dialog v-model="dialog" max-width="290" content-class="dialogueBox">
            <v-card>
                <v-card-title>{{ $t("download") }}</v-card-title>
                <v-card-text pa-0 ma-0>{{ $t("contains_media") }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn :ripple="false" color="#373737" text @click="
                        dialog = false;
                    downloadMedia();
                    ">
                        {{ $t("download_media") }}
                    </v-btn>

                    <v-btn :ripple="false" color="#373737" text @click="
                        dialog = false;
                    downloadText();
                    ">
                        {{ $t("download_text") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar transition="slide-y-transition" v-model="snackbar" :timeout="2000" bottom rounded color="primary">
            Downloading...
        </v-snackbar>
    </v-container>
</template>

<script>
import ItemModel from "../models/itemmodel";
import ItemFavoriteButton from "./ItemFavoriteButton";

export default {
    components: {
        ItemFavoriteButton,
    },
    props: {
        showDownload: {
            type: Boolean,
            default: function () {
                return true;
            },
        },
        showFavorite: {
            type: Boolean,
            default: function () {
                return true;
            },
        },
        item: {
            type: ItemModel,
            default: function () {
                return new ItemModel();
            },
        },

        /**
         * "article", "audio" or "video"
         */
        mediaType: {
            type: String,
            default: function () {
                return "article";
            },
        },
        dark: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    data() {
        return {
            dialog: false,
            snackbar: false,
        };
    },
    computed: {
        showShare: function () {
            // eslint-disable-next-line
            return (
                navigator.share != null ||
                window.navigator.share != null ||
                (window.NativeInterface !== undefined && window.NativeInterface.canShare())
            );
        },
        showShareFacebook: function () {
            return false;
        },
        btnColor() {
            return {
                "--button-color": this.dark ? "#8e8e8e" : "rgba(0,0,0,.87)",
            };
        },
    },
    methods: {
        share() {
            this.$root.appInstance.componentInstance.shareItem(this.item);
        },
        shareFacebook() {
            this.$root.appInstance.componentInstance.shareItemFacebook(this.item);
        },
        download() {
            if (this.item.hasVideoAttachment() || this.item.hasAudioAttachment()) {
                this.dialog = true;
            } else {
                this.downloadText();
            }
        },
        downloadMedia() {
            this.$root.appInstance.componentInstance.downloadMedia(this.item);
            this.dialog = false;
            this.snackbar = true;
        },

        downloadText() {
            this.$root.appInstance.componentInstance.printItem(this.item);
            this.dialog = false;
            this.snackbar = true;
        },
    },
};
</script>

<style>
.dialogueBox {
    border-radius: 2px;
}

.v-btn {
    font-weight: 700;
    color: var(--button-color) !important;
}

.v-snack__wrapper {
    min-height: 42px !important;
    margin-bottom: 58px;
    max-width: 50%;
    min-width: 200px;
    z-index: 30;
}

.v-snack__content {
    padding: 8px 16px;
    margin: 0 auto;
    text-align: center;
}
</style>
