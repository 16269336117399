<template>
<svg width="12px" height="16px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" fill="#000000" fill-rule="nonzero" stroke="#000000">
            <path d="M11.1176462,14.8739749 L6.2029411,12.4276536 C6.0762734,12.3646968 5.9259325,12.3646968 5.7992647,12.4276536 L0.88235376,14.8739749 L0.88235376,1.27856518 C0.88235376,1.04318783 1.07987516,0.85237679 1.32353016,0.85237679 L10.6764698,0.85237679 C10.9201248,0.85237679 11.1176462,1.04318783 11.1176462,1.27856518 L11.1176462,14.8739749 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "favorite"
};
</script>

<style scoped>
g {
    stroke: currentColor !important;
    fill: currentColor !important;
}
</style>