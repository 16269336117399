<template>
  <div />
</template>

<script>
  import db from "../database"
  import ItemModel from "../models/itemmodel"
  import MediaCache from "../mediacache"

  export default {
    props: {
      item: {
        type: ItemModel,
        default: function() {
          return new ItemModel()
        },
      },
    },
    data: () => ({
      playerObject: null,
      itemTitle: "",
      autoPlay: false,
      isPlaying: false,
      imageUrl: null,
      enclosureURL: "",
      enclosureType: null,
      duration: 0,
      currentPlaySeconds: 0,
      currentPlayPercentage: 0,
      showOverlayControls: false,
      overlayTimeoutObject: null,
      error: null,
      canPlayCalled: false,
    }),
    mounted: function() {
      // Clean up any old "playhead:" stuff from localStorage.
      //
      let toRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith("playhead:")) {
          toRemove.push(key);
        }
      }
      toRemove.forEach((key) => {
        localStorage.removeItem(key);
      })

      this.update()
    },
    watch: {
      item: function(newValue, oldValue) {
        this.savePlayhead(oldValue);
        if (this.item) {
          this.autoPlay = this.item.autoplay !== false
          // eslint-disable-next-line
          this.item.autoplay = false // reset flag
        } else {
          this.autoPlay = false
        }

        this.update()
      },
    },

    destroyed: function() {
      MediaCache.releaseMedia(this.enclosureURL)
      this.savePlayhead(this.item);
      this.enclosureURL = null
    },

    computed: {
      timeFromPercentage() {
        if (this.duration) {
          // eslint-disable-next-line
          this.currentPlaySeconds =
            (this.duration * this.currentPlayPercentage) / 10000
          return this.currentPlaySeconds
        }
        return NaN
      },
    },
    filters: {
      timeInColonFormat: function(value) {
        let hours = parseInt(Math.floor(value / 3600))
        let minutes = parseInt(Math.floor((value - hours * 3600) / 60))
        let seconds = parseInt((value - (hours * 3600 + minutes * 60)) % 60)

        let dHours = hours > 9 ? hours : "0" + hours
        let dMins = minutes > 9 ? minutes : "0" + minutes
        let dSecs = seconds > 9 ? seconds : "0" + seconds
        if (hours > 0) {
          return dHours + ":" + dMins + ":" + dSecs
        }
        return dMins + ":" + dSecs
      },
    },
    methods: {
      // load(item, autoplay) {
      //   this.item = item;
      //   this.autoplay = autoplay;
      // },
      savePlayhead(item) {
        // Save the current playhead position for this media item (identified by url)
        //
        if (
          item != null &&
          item.enclosure != null &&
          this.playerObject != null
        ) {
          var url = item.enclosure
          if (this.playerObject.ended) {
            sessionStorage.removeItem("playhead:" + url);
          } else {
            var time = this.playerObject.currentTime
            sessionStorage.setItem("playhead:" + url, time)
          }
        }
      },

      /* Take care of actually loading the media into the player */
      loadMedia() {
        console.log("MediaPlayerBase - Load Media")
        if (this.playerObject != null) {
          this.playerObject.load()
        }
      },

      update() {
        if (
          this.item != null &&
          (this.item.hasVideoAttachment() || this.item.hasAudioAttachment())
        ) {
          console.log("Updating item!")
          this.itemTitle = this.item.title
          const self = this
          this.error = null
          this.canPlayCalled = false
          this.enclosure(function(url) {
            self.enclosureURL = url
            self.enclosureType = self.item.enclosureType
            self.pause()
            console.log("Call load media to url " + url)
            self.loadMedia()
          })
          this.currentPlaySeconds = 0
          this.currentPlayPercentage = 0
          this.duration = null
          this.imageUrl = this.item.imageSrc
          this.$store.commit("setMediaPlayerItem", this.item)

          // If no thumbnail, try generic feed image
          if (this.imageUrl == null) {
            db.getFeed(this.item.feed).then((feed) => {
              if (feed != null) {
                this.imageUrl = feed.imageUrl
              }
            })
          }
        } else {
          this.itemTitle = ""
          this.currentPlaySeconds = 0
          this.currentPlayPercentage = 0
          this.duration = null
          this.imageUrl = null
          this.pause()
          this.error = null
          this.canPlayCalled = false
        }
      },

      enclosure(callback) {
        if (this.item != null && this.item.enclosure != null) {
          MediaCache.getMedia(this.item.enclosure, false, function(url) {
            callback(url)
          })
        }
      },

      onCanPlay() {
        this.canPlayCalled = true
        if (this.item != null && this.autoPlay) {
          this.autoPlay = false
          console.log("Can play called")
          this.play()
        }
      },

      onLoadStart() {
        console.log("On load start called")
        this.error = null
        this.canPlayCalled = false
      },

      onLoaded() {
        if (this.item !== null) {
          console.log("On loaded called")
          this.error = null

          if (this.item && this.item.enclosureDuration) {
            // Hardcoded duration, like for live radio shows
            this.duration = this.item.enclosureDuration
          } else if (this.playerObject != null) {
            this.duration = this.playerObject.duration

            // Store in item
            // eslint-disable-next-line
            this.item.enclosureDuration = this.duration
          } else {
            this.duration = 0
          }

          // Check if we have stored a playhead position for this media item
          //
          if (this.item != null && !this.item.isLive) {
            var url = this.item.enclosure
            if (sessionStorage.getItem("playhead:" + url) != null) {
              let time = sessionStorage.getItem("playhead:" + url)
              // TODO - dont do this for live
              if (this.playerObject != null) {
                this.playerObject.currentTime = time
              }
            }
          }

          if ("mediaSession" in navigator && this.item != null) {
            let meta = {
              title: this.item.title,
            }
            // eslint-disable-next-line
            navigator.mediaSession.metadata = new MediaMetadata(meta)
            db.getFeed(this.item.feed).then((feed) => {
              if (feed != null) {
                navigator.mediaSession.metadata.artist = feed.title
                if (feed.imageUrl != null) {
                  console.log("Set artwork to " + feed.imageUrl)
                  navigator.mediaSession.metadata.artwork = [
                    {
                      src: feed.imageUrl,
                    },
                  ]
                }
              }
            })
          }
        }
      },

      onErrorWithUrl(url, e) {
        this.onError(e)

        // Log fetch error
        this.$logger.logFetchError(url)
      },
      onError(e) {
        console.log("ERROR")
        console.log(e)
        if (this.enclosure != null && this.enclosure != "") {
          if (!e.target || e.target.src != "") {
            this.error = e
          }
        }
      },
      onSeeked() {
        this.savePlayhead(this.item);
      },
      onPlay() {
        this.isPlaying = true
        if (this.canPlayCalled) {
          // If we have loaded, log event
          this.$logger.logMediaPlay(this.item)
        }
      },

      onPaused() {
        this.isPlaying = false
        this.savePlayhead(this.item);
        if (this.playerObject && this.playerObject.ended) {
          // Played to completion, don't log a pause event
          return
        }
        this.$logger.logMediaPause(this.item)
      },

      onEnded() {
        this.$logger.logMediaComplete(this.item)
      },

      play() {
        try {
          const playPromise = this.playerObject.play()
          if (playPromise) {
            playPromise
              .then(() => {
                console.log("Play ok")
                this.$store.commit("setIsAudioPlaying", true)
              })
              .catch((err) => {
                console.log("Play fail", err)
              })
          }
        } catch (e) {
          console.log("Error playing: " + e)
        }
      },

      pause() {
        if (this.playerObject != null && this.isPlaying) {
          console.log("Calling pause")
          this.playerObject.pause()
        }
      },

      /**
       * Percentage is actually percent * 100
       */
      seekToPercentage(percentage) {
        if (this.playerObject != null) {
          this.playerObject.currentTime =
            (this.playerObject.duration * percentage) / 10000
        }
      },

      replay10() {
        if (this.playerObject != null) {
          this.playerObject.currentTime = Math.max(
            0,
            this.playerObject.currentTime - 10
          )
        }
      },
      forward10() {
        if (this.playerObject != null) {
          this.playerObject.currentTime = Math.min(
            this.playerObject.duration,
            this.playerObject.currentTime + 10
          )
        }
      },

      /*
          Return an object with {current:<num>,duration:<num>} for progress. Can be overridden by radio
          etc. to show correct program lengths */
      getCurrentAndDuration() {
        var duration = 0
        var current = 0
        if (this.playerObject != null) {
          duration = this.playerObject.duration
          current = Math.round(this.playerObject.currentTime)
        }
        return {
          current: current,
          duration: duration,
        }
      },

      onTimeUpdate() {
        // Update progress bar of current playback. TODO - allow click on progress bar to seek.
        var {duration, current} = this.getCurrentAndDuration()
        if (current != this.currentPlaySeconds) {
          this.currentPlaySeconds = current
          const oldPercentage = this.currentPlayPercentage
          this.currentPlayPercentage = (10000 * current) / duration

          if (!isNaN(duration) && isFinite(duration)) {
            this.duration = duration // In case it has changed
          }

          // Passed any media milestones? Remember that we are calculating in 100 * percent, so 10000 is max!
          if (
            !isNaN(oldPercentage) &&
            isFinite(oldPercentage) &&
            !isNaN(this.currentPlayPercentage) &&
            isFinite(this.currentPlayPercentage)
          ) {
            if (oldPercentage < 1000 && this.currentPlayPercentage >= 1000) {
              // 10% passed
              this.$logger.logMediaMilestone(this.item, 10)
            } else if (
              oldPercentage < 5000 &&
              this.currentPlayPercentage >= 5000
            ) {
              // 50% passed
              this.$logger.logMediaMilestone(this.item, 50)
            } else if (
              oldPercentage < 9000 &&
              this.currentPlayPercentage >= 9000
            ) {
              // 90% passed
              this.$logger.logMediaMilestone(this.item, 90)
            }
          }
        }
      },

      minimize() {
        this.showOverlayControls = false
        this.$emit("minimize")
      },

      maximize() {
        this.$emit("maximize")
      },

      close() {
        this.$store.commit("setIsAudioPlaying", false)
        console.log("Calling close")
        this.$emit("close")
      },

      showHideOverlayControls() {
        this.overlayTimeoutObject = null
        this.showOverlayControls = !this.showOverlayControls
        if (this.showOverlayControls) {
          this.overlayTimeoutObject = setTimeout(
            this.showHideOverlayControls,
            3000
          )
        }
      },

      enableOverlayControlsTimeout(enable) {
        if (this.overlayTimeoutObject != null) {
          clearTimeout(this.overlayTimeoutObject)
          this.overlayTimeoutObject = null
        }
        if (enable) {
          this.overlayTimeoutObject = setTimeout(
            this.showHideOverlayControls,
            3000
          )
        }
      },
    },
  }
</script>
