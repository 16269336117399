import Vue from 'vue';
import Logger from "./logger"

const IS_PRODUCTION = (process.env.NODE_ENV === "production");

var queuedTealiumStates = [];
var queuedTealiumActions = [];

// Initialize Tealium analytics
window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}; //Make sure we don't kill a previous decleration of the object
window.utag_cfg_ovrd.noview = true;

// append tealium tag and include a check so that we know when utag is loaded and ready for use in other scripts
(function (a, b, c, d) {
  a = '//tags.tiqcdn.com/utag/bbg/rfa-plone/' + (IS_PRODUCTION ? 'prod' : 'dev') + '/utag.js';
  b = document; c = 'script'; d = b.createElement(c); d.src = a; d.type = 'text/java' + c; d.async = true;
  a = b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d, a);

  // uses onload, for modern browsers
  d.onload = function () {
    if (!d.onloadDone) {
      d.onloadDone = true;
      onUtagLoaded();
    }
  };

  // uses onreadystatechange, for IE9
  d.onreadystatechange = function () {
    if (("loaded" === d.readyState || "complete" === d.readyState) && !d.onloadDone) {
      d.onloadDone = true;
      onUtagLoaded();
    }
  }

})();

function onUtagLoaded() {
  window.utag.cfg.datasource = "6g2fzf";

  // Send queued events
  for (var i = 0; i < queuedTealiumStates.length; i++) {
    //console.log("Send queued event: ");
    window.utag.view(queuedTealiumStates[i]); //, function() { utag.DB("Queued page view has been tracked"); });
  }
  queuedTealiumStates = [];
  for (i = 0; i < queuedTealiumActions.length; i++) {
    //console.log("Send queued event: ");
    window.utag.link(queuedTealiumActions[i]); //, function() { utag.DB("Queued page view has been tracked"); });
  }
  queuedTealiumActions = [];
}

class TealiumLogger extends Logger {
  getLogObject() {
    var event = {}; //this.getEmptyLogObject();
    event.property_id = "bbgrfadev1";
    if (!IS_PRODUCTION) {
      event.rsid_acct = "bbgrfadev1";
    } else {
      event.rsid_acct = "bbgprod, bbgentityrfa, bbgnewsappstsdk";
    }
    event.platform_type = "news app";
    event.entity = "rfa";
    event.app_type = "pwa";

    const url = window.location.origin + ((this.$root && this.$root.$route) ? this.$root.$route.fullPath : "");
    event.canonical_url = url;

    // Do this after the above, since we use those fields!
    event.app_id = event.entity + " " + event.app_type + " : " + this.$root.appVersionString;
    var langInfo = this.languageInfo();
    if (!IS_PRODUCTION) {
      // For development, use dev value here!
      delete langInfo.property_id;
    }
    Object.assign(event, langInfo);
    return event;
  }

  languageInfo() {
    let flavor = this.$store.state.flavor;
    switch (flavor) {
      case "uyghur":
      case "uyghur_latin":
      case "uyghur_cyrillic":
        return {
          language: "uyghur",
          language_service: "rfa uyghur",
          short_language_service: "uyg",
          property_name: "rfa uyghur news app",
          property_id: "49"
        };
      case "mandarin":
        return {
          language: "mandarin",
          language_service: "rfa mandarin",
          short_language_service: "man",
          property_name: "rfa mandarin news app",
          property_id: "47"
        };
      case "cantonese":
        return {
          language: "cantonese",
          language_service: "rfa cantonese",
          short_language_service: "can",
          property_name: "rfa cantonese news app",
          property_id: "42"
        };
      case "burmese":
        return {
          language: "burmese",
          language_service: "rfa burmese",
          short_language_service: "bur",
          property_name: "rfa burmese news app",
          property_id: "41"
        };
      case "lao":
        return {
          language: "lao",
          language_service: "rfa lao",
          short_language_service: "lao",
          property_name: "rfa lao news app",
          property_id: "31"
        };
      case "korean":
        return {
          language: "korean",
          language_service: "rfa korean",
          short_language_service: "kor",
          property_name: "rfa korean news app",
          property_id: "30"
        };
      case "khmer":
        return {
          language: "khmer",
          language_service: "rfa khmer",
          short_language_service: "khm",
          property_name: "rfa khmer news app",
          property_id: "44"
        };
      case "vietnamese":
        return {
          language: "vietnamese",
          language_service: "rfa vietnamese",
          short_language_service: "vie",
          property_name: "rfa vietnamese news app",
          property_id: "50"
        };
      case "tibetan":
        return {
          language: "tibetan",
          language_service: "rfa tibetan",
          short_language_service: "tib",
          property_name: "rfa tibetan news app",
          property_id: "48"
        };
      default:
        return {
          language: "english",
          language_service: "rfa english",
          short_language_service: "eng",
          property_name: "rfa english news app",
          property_id: "43"
        };
    }
  }

  logStateOnboardingScreen() {
    var event = this.getLogObject();
    event.page_name = "app onboarding";
    event.content_type = "app onboarding";
    event.subcontent_type = "app onboarding";
    event.section = "app onboarding";
    event.page_title = "app onboarding";
    this.logStateEvent(event);
  }

  logStateHomeScreen() {
    var event = this.getLogObject();
    event.page_name = "home screen";
    event.content_type = "index";
    event.subcontent_type = "homepage";
    event.section = "homepage";
    event.page_title = "home screen";
    this.logStateEvent(event);
  }

  logStateCategoriesScreen() {
    // Get default category name
    let cat = this.$store.state.currentFeedCategories[0];
    let category = cat.category || "default";

    var event = this.getLogObject();
    event.page_name = category;
    event.content_type = "index";
    event.subcontent_type = "section";
    event.section = category;
    event.page_title = category;
    this.logStateEvent(event);
  }

  logStateCategorySelected(category) {
    var event = this.getLogObject();
    event.page_name = category;
    event.content_type = "categories";
    event.subcontent_type = category;
    event.section = category;
    event.page_title = category;
    this.logStateEvent(event);
  }

  logStateSettingsScreen() {
    var event = this.getLogObject();
    event.page_name = "settings";
    event.content_type = "settings";
    event.subcontent_type = "settings";
    event.section = "settings";
    event.page_title = "settings";
    this.logStateEvent(event);
  }

  logStateSavedScreen(selectedTimeFrame) {
    var timeFrame = selectedTimeFrame || "saved";
    if (timeFrame == "week") {
      timeFrame = "this week";
    } else if (timeFrame == "month") {
      timeFrame = "this month";
    }
    var event = this.getLogObject();
    event.page_name = timeFrame;
    event.content_type = "bookmarks";
    event.subcontent_type = "bookmarks";
    event.section = timeFrame;
    event.page_title = timeFrame;
    this.logStateEvent(event);
  }

  logStateEvent(event) {
    console.log(event);
    if (process.env.NODE_ENV === "production") {
      if (window.utag !== undefined) {
        window.utag.view(event); //, function() { utag.DB("page view has been tracked"); });
      } else {
        // Queue for sending later, when loaded
        queuedTealiumStates.push(event);
      }
    }
  }

  logActionEvent(event) {
    delete event.page_title;
    console.log(event);
    if (process.env.NODE_ENV === "production") {
      if (window.utag !== undefined) {
        window.utag.link(event);
      } else {
        // Queue for sending later, when loaded
        queuedTealiumActions.push(event);
      }
    }
  }

  logPageView(pageName) {
    switch (pageName) {
      case "onboarding":
        this.logStateOnboardingScreen();
        break;
      case "home":
        this.logStateHomeScreen();
        break;
      case "categories":
        this.logStateCategoriesScreen();
        break;
      case "more":
        this.logStateSettingsScreen();
        break;
      case "saved":
        this.logStateSavedScreen();
        break;
    }
  }

  logHeaderTagSelected(tag) {
    if (tag != null) {
      if (tag.value.startsWith("cat_")) {
        this.logStateCategorySelected(tag.name);
      } else if (tag.value.startsWith("saved_")) {
        this.logStateSavedScreen(tag.value.substr(6));
      }
    }
  }

  setArticleProperties(event, item, includePageTitle) {
    event.page_name = item.title;
    event.section = item.getCategoryName();
    if (includePageTitle) {
      event.page_title = item.title;
    }
    if (item.author != null && item.author != "") {
      event.byline = item.author;
    } else {
      // Default to language service
      event.byline = event.language_service;
    }

    var moment = require('moment'); // require
    let date = moment.utc(item.pubDate);
    event.pub_date = date.format("MM/DD/YYYY");
    event.pub_hour = date.format("HH");
    if (item.description != null && item.description != "") {
      event.slug = item.description;
    }
    event.article_uid = item.guid;
    if (item.tags != null && item.tags != "") {
      event.tags = item.tags;
    }
  }

  logArticleView(item) {
    var event = this.getLogObject();
    let type = "article";
    if (!item.content && item.hasVideoAttachment()) {
      type = "video";
    } else if (!item.content && item.hasAudioAttachment()) {
      type = "audio";
    }
    event.content_type = type;
    event.subcontent_type = type;
    this.setArticleProperties(event, item, true);
    this.logStateEvent(event);
  }

  logVideoListView() {
    var event = this.getLogObject();
    event.page_name = "video detail index";
    event.content_type = "index";
    event.subcontent_type = "video detail index";
    event.section = "video detail";
    event.page_title = "video detail index";
    this.logStateEvent(event);
  }

  logAudioListView() {
    var event = this.getLogObject();
    event.page_name = "audio detail index";
    event.content_type = "index";
    event.subcontent_type = "audio detail index";
    event.section = "audio detail";
    event.page_title = "audio detail index";
    this.logStateEvent(event);
  }

  // Actions
  //

  logAppFirstLoad() {
    var event = this.getLogObject();
    event.KEY = "app_first_launch";
    event.page_name = "app first-launch";
    event.content_type = "app behavior";
    event.subcontent_type = "app behavior";
    event.section = "app behavior";
    event.app_events = "app_first_launch";
    this.logActionEvent(event);
  }

  logAppLoad() {
    var event = this.getLogObject();
    event.KEY = "app_launch";
    event.page_name = "app launch";
    event.content_type = "app behavior";
    event.subcontent_type = "app behavior";
    event.section = "app behavior";
    event.app_events = "app_launch";
    this.logActionEvent(event);
  }

  logAppExit() {
    // var event = this.getLogObject();
    // event.KEY = "app_exit";
    // event.page_name = "app exit";
    // event.content_type = "app behavior";
    // event.subcontent_type = "app behavior";
    // event.section = "app behavior";
    // event.app_events = "app_exit";
    // this.logActionEvent(event);
  }

  logAppError() {
    var event = this.getLogObject();
    event.KEY = "app_crash";
    event.page_name = "app error";
    event.content_type = "app behavior";
    event.subcontent_type = "app behavior";
    event.section = "app behavior";
    event.app_events = "app_crash";
    this.logActionEvent(event);
  }

  logAppUpdated() {
    var event = this.getLogObject();
    event.KEY = "app_update";
    event.page_name = "app update";
    event.content_type = "app behavior";
    event.subcontent_type = "app behavior";
    event.section = "app behavior";
    event.app_events = "app_update";
    this.logActionEvent(event);
  }

  logFetchError(url) {
    if (url === undefined || url == null || url == "") {
      return; // ignore empty
    }
    var event = this.getLogObject();
    event.KEY = "content_not_found";
    event.page_name = "content load error";
    event.content_type = "load error";
    event.subcontent_type = "load error";
    event.section = "content error";
    event.app_events = "content_not_found";

    event.canonical_url = url; // Is this correct?
    this.logActionEvent(event);
  }

  logLanguageSelect(language, fromSettings) {
    var event = this.getLogObject();
    event.KEY = "language_fav";
    var type = fromSettings ? "settings" : "app onboarding";
    event.page_name = type;
    event.content_type = type;
    event.subcontent_type = type;
    event.section = type;
    event.app_events = language;
    this.logActionEvent(event);
  }

  logArticleFavorited(item, type) {
    var event = this.getLogObject();
    this.setArticleProperties(event, item, false);
    event.content_type = type;
    event.subcontent_type = type;
    if (type == "audio") {
      event.KEY = "media_bookmarked";
      event.app_events = "media_bookmarked";
      event.media_type = "ondemand audio";
    } else if (type == "video") {
      event.KEY = "media_bookmarked";
      event.app_events = "media_bookmarked";
      event.media_type = "ondemand video";
    } else {
      event.KEY = "article_to_fav";
      event.app_events = "article_to_fav";
    }
    this.logActionEvent(event);
  }

  logArticleDownload(item, type) {
    var event = this.getLogObject();
    this.setArticleProperties(event, item, false);
    event.content_type = type;
    event.subcontent_type = type;
    if (type == "audio") {
      event.KEY = "download_start";
      event.audio_name = item.title;
      event.media_type = "ondemand audio";
      event.app_events = "download_media";
    } else if (type == "video") {
      event.KEY = "download_start";
      event.video_name = item.title;
      event.media_type = "ondemand video";
      event.app_events = "download_media";
    } else {
      event.KEY = "download_start";
      event.app_events = "download_article";
    }
    this.logActionEvent(event);
  }

  logArticleShare(item, type) {
    var event = this.getLogObject();
    this.setArticleProperties(event, item, false);
    event.content_type = type;
    event.subcontent_type = type;
    event.KEY = "social_share";
    if (type == "audio") {
      event.audio_name = item.title;
      event.app_events = "download_media";
    } else if (type == "video") {
      event.video_name = item.title;
      event.app_events = "download_media";
    } else {
      event.app_events = "download_article";
    }
    this.logActionEvent(event);
  }

  logTextSizeSelect(newSize) {
    var event = this.getLogObject();
    event.KEY = "text_size";
    event.page_name = "settings";
    event.content_type = "settings";
    event.subcontent_type = "settings";
    event.section = "settings";
    event.app_events = "text_size_" + newSize;
    this.logActionEvent(event);
  }

  logClosePlayer(item, type) {
    if (item == null) {
      return;
    }
    var event = this.getLogObject();
    event.KEY = "player_x";
    event.page_name = "player close";
    event.content_type = type;
    event.subcontent_type = type;
    event.section = item.getCategoryName();
    if (type == "audio") {
      event.audio_name = item.title;
      event.media_type = "ondemand audio";
    } else if (type == "video") {
      event.video_name = item.title;
      event.media_type = "ondemand video";
    }
    event.app_events = "player_x";
    this.logActionEvent(event);
  }

  logNavigation(pageName) {
    var event = this.getLogObject();
    switch (pageName) {
      case "home":
        event.KEY = "home_nav";
        event.content_type = "home";
        event.subcontent_type = "home";
        event.page_name = "home nav";
        event.app_events = "home_nav"
        break;
      case "categories":
        event.KEY = "category_nav";
        event.content_type = "categories";
        event.subcontent_type = "categories";
        event.page_name = "category nav";
        event.app_events = "category_nav"
        break;
      case "radio":
        event.KEY = "audio_nav";
        event.content_type = "radio";
        event.subcontent_type = "radio";
        event.page_name = "audio nav";
        event.app_events = "audio_nav"
        break;
      case "more":
        event.KEY = "settings_nav";
        event.content_type = "settings";
        event.subcontent_type = "settings";
        event.page_name = "settings nav";
        event.app_events = "settings_nav"
        break;
      default:
        return;
    }
    this.logActionEvent(event);
  }

  /**
   * eventType: "play", "pause" or "complete"
   */
  logMediaEvent(item, eventTypeVideo, eventTypeAudio) {
    if (item == null || (!item.hasVideoAttachment() && !item.hasAudioAttachment())) {
      return;
    }
    let isVideo = item.hasVideoAttachment();
    var event = this.getLogObject();
    event.page_name = item.title;
    //event.page_title = item.title;
    event.me_content_type = isVideo ? "video" : "audio";
    event.subcontent_type = isVideo ? "video" : "audio";
    event.section = item.getCategoryName();
    if (isVideo) {
      event.KEY = eventTypeVideo;
      event.video_name = item.title;
      event.media_type = item.isLive ? "live video stream" : "ondemand video";
      event.app_events = eventTypeVideo;
      event.video_length = parseInt(item.enclosureDuration || 0, 10);
    } else {
      event.KEY = eventTypeAudio;
      event.audio_name = item.title;
      event.media_type = item.isLive ? "live audio stream" : "ondemand audio";
      event.app_events = eventTypeAudio;
      event.audio_length = parseInt(item.enclosureDuration || 0, 10);
    }
    this.logActionEvent(event);
  }

  logMediaPlay(item) {
    this.logMediaEvent(item, "video_play", "audio_play");
  }

  logMediaPause(item) {
    this.logMediaEvent(item, "video_pause", "audio_pause");
  }

  logMediaComplete(item) {
    this.logMediaEvent(item, "video_complete", "audio_complete");
  }

  logMediaMilestone(item, percent) {
    if (percent != 10 && percent != 50 && percent != 100) return;
    this.logMediaEvent(item, `media_milestone_${percent}_video`, `media_milestone_${percent}_audio`);
  }
}

const tealiumLogger = new TealiumLogger();
const tealiumPlugin = {}
tealiumPlugin.install = function (Vue, ignoredOptions) {
  Vue.prototype.$logger = tealiumLogger;

  Vue.mixin({
    mounted: function () {
      if (this instanceof Vue) {
        // Store the VUE instance root in our own $root variable.
        tealiumLogger.$root = tealiumLogger.$root || this.$root;
        tealiumLogger.$store = tealiumLogger.$store || this.$root.$store;
      }
    }
  })
}

Vue.use(tealiumPlugin);

export default tealiumLogger;
