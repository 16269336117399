// eslint-disable-next-line
(function() {
  class ProxyHandlerClass {
    constructor() {
      // Don't change this line, it's set/updated in vue.config.js as part of the build process.
      const USE_PRODUCTION_PROXIES = false;
      if (USE_PRODUCTION_PROXIES) {
        this.proxies = [
          "https://d2211uosn9owfs.cloudfront.net",
          "https://d19qwpgivh569r.cloudfront.net",
          "https://d2kj7xdvlwu60j.cloudfront.net",
          "https://d16g1qcztogxj0.cloudfront.net",
        ];
      } else {
        this.proxies = ["https://rfa-anynews-dev.gpcmdln.net"];
      }
      this.proxiedUrls = [
        "https://*.rfa.org",
        "https://*.kaltura.com",
        "https://*.acast.com",
        "https://pippa.io",
        "https://*.pippa.io"
      ];
      this.idxCurrentProxy = 0;
      this.useDevServer = false;
      this.useStaticProxy = false; // Set to true to replace all URLs in incoming data
      this.setRandomProxy(); // Init to random
    }

    getCurrentProxy = function() {
      if (this.useDevServer == true) {
        // For development, use the development proxy set up in vue.config.js
        return "/rfa";
      }
      return this.proxies[this.idxCurrentProxy];
    };

    setCurrentProxy = function(proxy) {
      if (proxy) {
        this.idxCurrentProxy = this.proxies.indexOf(proxy);
        if (this.idxCurrentProxy >= 0) {
          return; // success
        }
      }

      // Not set or invlid, use a random one!
      this.setRandomProxy();
    };

    setRandomProxy() {
      this.idxCurrentProxy = Math.floor(
        Math.random() * Math.floor(this.proxies.length)
      );
      console.log("RANDOM PROXY ----> " + this.getCurrentProxy());
    }

    moveToNextProxy = function() {
      this.idxCurrentProxy = (this.idxCurrentProxy + 1) % this.proxies.length;
      console.log("NEXT PROXY ----> " + this.getCurrentProxy());
      return this.getCurrentProxy();
    };

    ensureTrailingSlash(url) {
      if (url.endsWith("/")) {
        return url;
      }
      return url + "/";
    }

    getProxiedUrl(urlString) {
      if (urlString && !urlString.startsWith(".") && !urlString.startsWith("/")) {
          try {
              const url = new URL(urlString); 
              for (let p of this.proxiedUrls) {
                  try {
                      let proxyProtocol =  p.split("//")[0];
                      let proxyHost = p.split("://")[1];

                      // Get hostname by stripping away path and optional port
                      //
                      let proxyHostname = proxyHost;
                      let idxSlash = proxyHostname.indexOf("/");
                      let idxColon = proxyHostname.indexOf(":");
                      if (idxSlash >= 0) {
                          proxyHostname = proxyHostname.substring(0, idxSlash);
                      }
                      if (idxColon >= 0 && idxColon < idxSlash) {
                          proxyHostname = proxyHostname.substring(0, idxColon);
                      }

                      if (url.protocol == proxyProtocol) {
                          const proxyHostParts = proxyHost.split(".");
                          const urlHostnameParts = url.hostname.split(".");
                          if (proxyHostParts.length == urlHostnameParts.length && proxyHostParts.every((part, index) => part == "*" || part == urlHostnameParts[index])) {
                              const proxy = this.getCurrentProxy();
                              const urlRewritten = this.ensureTrailingSlash(proxy) + "proxy/" + url.hostname + url.pathname + url.search;
                              console.log("URL rewrite: " + url + " -> " + urlRewritten);
                              return urlRewritten;
                          }
                      }
                  }
                  catch (ex) {
                      console.error("Invalid proxy url", p, ex);
                  }
              }
          }
          catch (ex) {
            console.warn(ex);
          }
      }
      return urlString;
    }
  }
  this.ProxyHandler = new ProxyHandlerClass();
})();
