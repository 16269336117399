<template>
  <svg
    width="10px"
    height="6px"
    viewBox="0 0 10 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Home" transform="translate(-175.000000, -757.000000)">
        <g
          id="media/watch"
          transform="translate(174.000000, 754.000000)"
          fill="#949494"
          fill-rule="nonzero"
        >
          <path
            d="M8.88888889,5.25 L8.88888889,3.5 C8.88888889,3.225 8.65138889,3 8.36111111,3 L2.02777778,3 C1.7375,3 1.5,3.225 1.5,3.5 L1.5,8.5 C1.5,8.775 1.7375,9 2.02777778,9 L8.36111111,9 C8.65138889,9 8.88888889,8.775 8.88888889,8.5 L8.88888889,6.75 L11,8.75 L11,3.25 L8.88888889,5.25 Z"
            id="Path-Copy-10"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "typeVideo"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>