<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 10 10"
    >
        <g>
            <path
                d="M1.3,4.6c0,0,0.1,0,0.1,0c2.7,0,5.4,0,8,0c0.1,0,0.1,0,0.2,0C9.9,4.6,10,4.8,10,5c0,0.2-0.2,0.4-0.4,0.4c0,0-0.1,0-0.1,0
	c-2.7,0-5.4,0-8,0c0,0-0.1,0-0.2,0c0.1,0.1,0.1,0.1,0.2,0.1c1,0.9,1.9,1.8,2.9,2.7c0.1,0,0.1,0.1,0.1,0.1c0.2,0.2,0.2,0.4,0,0.6
	C4.3,9.2,4.1,9.2,3.9,9c-0.3-0.3-0.7-0.6-1-1C2,7.3,1.2,6.5,0.3,5.7c-0.4-0.4-0.4-0.9,0-1.3C1.5,3.2,2.7,2.1,3.8,1
	C4,0.9,4.1,0.8,4.2,0.8c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0,0.3-0.1,0.4c-0.3,0.3-0.7,0.6-1,1C2.7,3.2,2,3.8,1.3,4.6
	C1.3,4.5,1.3,4.5,1.3,4.6C1.3,4.6,1.3,4.6,1.3,4.6z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: "back",
    };
</script>

<style scoped>
    g {
        fill: currentColor !important;
    }
</style>
