<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 556 240">
    <g data-name="Layer 1">
      <path d="M90 102a101 101 0 0 1 43-29A101 101 0 0 0 0 169a71 71 0 1 0 90-67Z" fill="#61c230"/>
      <path d="M520 68v16a86 86 0 1 0 0 140v16h36V68Zm-50 136a50 50 0 1 1 50-50 50 50 0 0 1-50 50ZM230 88V68h-36v172h36v-86a53 53 0 0 1 51-53V68c-31 0-48 17-51 20ZM384 68h-30V50a20 20 0 0 1 20-20h10V0h-26c-23 0-41 24-40 42v26h-19v33h19v139h36V101h30Z" fill="#627568"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "logo"
};
</script>

<style scoped>
g {
    stroke: currentColor !important;
    fill: currentColor !important;
}
</style>
