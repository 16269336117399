import moment from "moment";

moment.defineLocale("ug-latin", {
  months: "Yanwar_féwral_mart_aprél_may_iyun_iyul_awghust_séntebir_öktebir_noyabir_dékabir".split(
    "_"
  ),
  monthsShort: "Yanwar_féwral_mart_aprél_may_iyun_iyul_awghust_séntebir_öktebir_noyabir_dékabir".split(
    "_"
  ),
  weekdays: "Yekshenbe_düshenbe_seyshenbe_charshenbe_peyshenbe_jüme_shenbe".split(
    "_"
  ),
  weekdaysShort: "Ye_dü_se_cha_pe_jü_she".split("_"),
  weekdaysMin: "Ye_dü_se_cha_pe_jü_she".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY-MM-DD",
    LL: "YYYY-[Yili]M-[ayning]D-[küni]",
    LLL: "YYYY-[Yili]M-[ayning]D-[küni], HH:mm",
    LLLL: "dddd, YYYY-[yili]M-[ayning]D-[küni], HH:mm",
  },
  meridiemParse: /Yérim kéche|Seher|Chüshtin burun|Chüsh|Chüshtin kéyin|Kech/,
  meridiemHour: function (hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (
      meridiem === "Yérim kéche" ||
      meridiem === "Seher" ||
      meridiem === "Chüshtin burun"
    ) {
      return hour;
    } else if (meridiem === "Chüshtin kéyin" || meridiem === "Kech") {
      return hour + 12;
    } else {
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem: function (hour, minute, ignoredisLower) {
    var hm = hour * 100 + minute;
    if (hm < 600) {
      return "Yérim kéche";
    } else if (hm < 900) {
      return "Seher";
    } else if (hm < 1130) {
      return "Chüshtin burun";
    } else if (hm < 1230) {
      return "Chüsh";
    } else if (hm < 1800) {
      return "Chüshtin kéyin";
    } else {
      return "Kech";
    }
  },
  calendar: {
    sameDay: "[Bügün sa’et] LT ",
    nextDay: "[Ete sa’et] LT ",
    nextWeek: "[Kélerki] dddd [sa’et] LT ",
    lastDay: "[Tönügün] LT",
    lastWeek: "[Aldinqi] dddd [sa’et] LT ",
    sameElse: "L",
  },
  relativeTime: {
    future: "%s Kéyin",
    past: "%s Burun",
    s: "Nechche sékont",
    ss: "%d Sékont",
    m: "Bir minut",
    mm: "%d Minut",
    h: "Bir sa’et ",
    hh: "%d Sa’et ",
    d: "Bir kün",
    dd: "%d Kün",
    M: "Bir ay",
    MM: "%d Ay",
    y: "Bir yil",
    yy: "%d Yil",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(-Küni|-Ay|-Hepte)/,
  ordinal: function (number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "-Küni";
      case "w":
      case "W":
        return number + "-Hepte";
      default:
        return number;
    }
  },
  preparse: function (string) {
    return string.replace(/,/g, ",");
  },
  postformat: function (string) {
    return string.replace(/,/g, ",");
  },
  week: {
    // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
    dow: 1, // Monday is the first day of the week.
    doy: 7, // The week that contains Jan 1st is the first week of the year.
  },
});
