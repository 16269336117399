<template>

<svg width="18px" height="22px" viewBox="0 0 18 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="av-controls/black/forward" transform="translate(-3.000000, -2.000000)" fill="#000000" fill-rule="nonzero">
            <path d="M10.9428304,6.32402822 C7.09348595,6.84049998 4.125,10.1379263 4.125,14.1286797 C4.125,18.4779221 7.6507576,22.0036797 12,22.0036797 C16.3492424,22.0036797 19.875,18.4779221 19.875,14.1286797 C19.875,13.8180195 20.1268398,13.5661797 20.4375,13.5661797 C20.7481602,13.5661797 21,13.8180195 21,14.1286797 C21,19.0992424 16.9705627,23.1286797 12,23.1286797 C7.02943725,23.1286797 3,19.0992424 3,14.1286797 C3,9.51576266 6.47044012,5.71341039 10.9428304,5.19011284 L10.9428304,3.41421356 C10.9428304,2.86192881 11.3905456,2.41421356 11.9428304,2.41421356 C12.2080469,2.41421356 12.4624008,2.5195704 12.6499371,2.70710678 L14.9634647,5.02063439 C15.353989,5.41115868 15.353989,6.04432366 14.9634647,6.43484795 L12.6499371,8.74837555 C12.2594128,9.13889984 11.6262479,9.13889984 11.2357236,8.74837555 C11.0481872,8.56083917 10.9428304,8.30648526 10.9428304,8.04126877 L10.9428304,6.32402822 Z" id="Combined-Shape"></path>
            <path d="M11,18.1286797 L9.78797997,18.1286797 L9.78797997,13.3278583 L8.703125,13.715808 L8.703125,12.8739189 L10.8447412,12.1286797 L11,12.1286797 L11,18.1286797 Z M16,15.5899096 C16,16.429486 15.8356085,17.0625011 15.5068206,17.4889738 C15.1780326,17.9154465 14.6778627,18.1286797 14.0062959,18.1286797 C13.3459218,18.1286797 12.8485501,17.920794 12.5141658,17.5050166 C12.1797815,17.0892391 12.0083946,16.4736035 12,15.6580914 L12,14.6473962 C12,13.8078198 12.1643915,13.178147 12.4931794,12.7583588 C12.8219674,12.3385706 13.3235363,12.1286797 13.9979014,12.1286797 C14.66667,12.1286797 15.1654408,12.3332231 15.4942288,12.742316 C15.8230167,13.151409 15.9916054,13.7637023 16,14.5792144 L16,15.5899096 Z M15,14.559574 C15,14.0657443 14.9202287,13.7038088 14.7606838,13.4737564 C14.6011388,13.2437041 14.3475801,13.1286797 14,13.1286797 C13.660967,13.1286797 13.4116817,13.2376819 13.2521368,13.4556896 C13.0925918,13.6736973 13.0085471,14.0139529 13,14.4764665 L13,15.6688784 C13,16.1602991 13.0811958,16.5264502 13.2435897,16.7673427 C13.4059837,17.0082352 13.6609669,17.1286797 14.008547,17.1286797 C14.341882,17.1286797 14.5883183,17.0172685 14.7478632,16.794443 C14.9074082,16.5716174 14.9914529,16.2229307 15,15.7483725 L15,14.559574 Z" id="10"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "forward10"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>