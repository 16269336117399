
<template>

<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="actions/icons/empty/facebook" transform="translate(-4.000000, -4.000000)" fill="#000000" fill-rule="nonzero">
            <g id="iconmonstr-facebook-6" transform="translate(4.000000, 4.000000)">
                <path d="M15.1166667,0 L0.883333333,0 C0.395333333,0 0,0.395333333 0,0.883333333 L0,15.1173333 C0,15.6046667 0.395333333,16 0.883333333,16 L8.54666667,16 L8.54666667,9.804 L6.46133333,9.804 L6.46133333,7.38933333 L8.54666667,7.38933333 L8.54666667,5.60866667 C8.54666667,3.542 9.80866667,2.41666667 11.6526667,2.41666667 C12.536,2.41666667 13.2946667,2.48266667 13.516,2.512 L13.516,4.672 L12.2373333,4.67266667 C11.2346667,4.67266667 11.0406667,5.14933333 11.0406667,5.848 L11.0406667,7.39 L13.432,7.39 L13.1206667,9.80466667 L11.0406667,9.80466667 L11.0406667,16 L15.118,16 C15.6046667,16 16,15.6046667 16,15.1166667 L16,0.883333333 C16,0.395333333 15.6046667,0 15.1166667,0 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "facebook"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>