import moment from "moment";

moment.defineLocale("ug-cyrillic", {
  months: "январ_феврал_март_апрел_май_июн_июл_авғуст_сентәбир_өктәбир_ноябир_декабир".split(
    "_"
  ),
  monthsShort: "январ_феврал_март_апрел_май_июн_июл_авғуст_сентәбир_өктәбир_ноябир_декабир".split(
    "_"
  ),
  weekdays: "Йәкшәнбә_дүшәнбә_сәйшәнбә_чаршәнбә_пәйшәнбә_җүмә_шәнбә".split("_"),
  weekdaysShort: "Йә_дү_сә_ча_пә_җү_шә".split("_"),
  weekdaysMin: "Йә_дү_сә_ча_пә_җү_шә".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY-MM-DD",
    LL: "YYYY-ЙилиM-айниңD-күни",
    LLL: "YYYY-ЙилиM-айниңD-күни, HH:mm",
    LLLL: "dddd, YYYY-йилиM-айниңD-күни, HH:mm",
  },
  meridiemParse: /Йерим кечә|Сәһәр|Чүштин бурун|Чүш|Чүштин кейин|Кәч/,
  meridiemHour: function (hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (
      meridiem === "Йерим кечә" ||
      meridiem === "Сәһәр" ||
      meridiem === "Чүштин бурун"
    ) {
      return hour;
    } else if (meridiem === "Чүштин кейин" || meridiem === "Кәч") {
      return hour + 12;
    } else {
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem: function (hour, minute, ignoredisLower) {
    var hm = hour * 100 + minute;
    if (hm < 600) {
      return "Йерим кечә";
    } else if (hm < 900) {
      return "Сәһәр";
    } else if (hm < 1130) {
      return "Чүштин бурун";
    } else if (hm < 1230) {
      return "Чүш";
    } else if (hm < 1800) {
      return "Чүштин кейин";
    } else {
      return "Кәч";
    }
  },
  calendar: {
    sameDay: "[Бүгүн саәт] LT",
    nextDay: "[Әтә саәт] LT",
    nextWeek: "[Келәрки] dddd [саәт] LT",
    lastDay: "[Төнүгүн] LT",
    lastWeek: "[Алдинқи] dddd [саәт] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "%s Кейин",
    past: "%s Бурун",
    s: "Нәччә секонт",
    ss: "%d Секонт",
    m: "Бир минут",
    mm: "%d Минут",
    h: "Бир саәт",
    hh: "%d Саәт",
    d: "Бир күн",
    dd: "%d Күн",
    M: "Бир ай",
    MM: "%d Ай",
    y: "Бир йил",
    yy: "%d Йил",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(-Күни|-Ай|-Һәптә)/,
  ordinal: function (number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "-Күни";
      case "w":
      case "W":
        return number + "-Һәптә";
      default:
        return number;
    }
  },
  preparse: function (string) {
    return string.replace(/,/g, ",");
  },
  postformat: function (string) {
    return string.replace(/,/g, ",");
  },
  week: {
    // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
    dow: 1, // Monday is the first day of the week.
    doy: 7, // The week that contains Jan 1st is the first week of the year.
  },
});
