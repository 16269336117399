<template>
  <svg
    width="10px"
    height="7px"
    viewBox="0 0 10 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Home" transform="translate(-75.000000, -942.000000)">
        <g id="media/read" transform="translate(74.000000, 940.000000)" fill="#949494">
          <g id="noun_read_1780232" transform="translate(1.000000, 2.500000)">
            <path
              d="M0,0.270435695 L0,6.12719039 C0.549073148,5.95144437 1.13310515,5.86220803 1.73721252,5.86220803 C2.68133221,5.86220803 3.6344512,6.08244828 4.5,6.5 L4.5,0.649690152 C3.63675871,0.224702069 2.68213984,0 1.73709715,0 C1.13218214,0 0.547919391,0.0910954333 0,0.270435695 Z"
              id="Path"
              fill-rule="nonzero"
            ></path>
            <path
              d="M8.2626721,0 C7.31786016,0 6.36324129,0.224573847 5.5,0.649677764 L5.5,6.5 C6.3655488,6.08258018 7.31878317,5.86234413 8.2626721,5.86234413 C8.86677948,5.86234413 9.45081148,5.95145483 10,6.12744537 L10,0.270430538 C9.45196523,0.0909697594 8.86770248,0 8.2626721,0 Z"
              id="Path"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "typeText"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>