<template>
    <v-lazy transition="slide-y-reverse-transition">
        <v-card
            :class="{
                dockedPlayer: true,
                dockedPlayerFullscreen: $store.state.fullScreenItems != null,
            }"
            v-if="$root.mediaPlayer != null"
            width="100%"
            :ripple="false"
            class="rounded-t-md d-flex align-center nowrap slide-top"
            height="auto"
            min-height="60"
            elevation="0"
        >
            <v-container
                fluid
                @click="toggleCard"
                v-touch:swipe.top="toggleCard"
                pa-0
                fill-height
                ma-0
            >
                <v-row
                    class="ma-0 px-3 pt-2 pb-1 justify-space-between"
                    style="overflow: hidden;height: 100%"
                    align="center"
                    max-width="100%"
                    transition-delay=".1s"
                >
                    <v-col class="ma-0 pa-0 py-1" cols="2" v-if="imageUrl != null">
                        <v-img
                            :aspect-ratio="1"
                            contain
                            :src="imageUrl"
                            @error="$logger.logFetchError(imageUrl)"
                            class="mx-auto pa-0 podimg"
                        />
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="7" style="flex-shrink: 0">
                        <div class="blurEnds">
                            <vue-marquee-slider
                                class="scrollTitle currentlyPlayingTitle"
                                ref="slider"
                                id="marquee-slider-loop"
                                :key="restartSlider"
                                :speed="speed"
                                :space="50"
                                :repeat="0"
                                :paused="false"
                                :autoWidth="true"
                            >
                                <div v-for="index in 2" :key="index">
                                    {{ itemTitle }}
                                </div>
                            </vue-marquee-slider>
                        </div>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="1" style=" flex-shrink: 1">
                        <v-btn
                            :ripple="false"
                            class="small-button"
                            v-show="!$root.mediaPlayer.isPlaying && $root.mediaPlayer.error == null"
                            text
                            icon
                            color="black"
                            @click.stop="$root.mediaPlayer.play()"
                        >
                            <v-icon dense>$vuetify.icons.play</v-icon>
                        </v-btn>
                        <v-btn
                            class="small-button"
                            :ripple="false"
                            v-show="$root.mediaPlayer.isPlaying && $root.mediaPlayer.error == null"
                            text
                            icon
                            color="black"
                            @click.stop="$root.mediaPlayer.pause()"
                        >
                            <v-icon dense>$vuetify.icons.pause</v-icon>
                        </v-btn>
                        <!-- Warning icon, showing error loading -->
                        <v-btn
                            class="medium-button"
                            :ripple="false"
                            v-show="$root.mediaPlayer.error != null"
                            text
                            icon
                            color="secondary"
                        >
                            <v-icon size="25">$vuetify.icons.warning</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="1" style="flex-shrink: 1">
                        <v-btn class="small-button" text icon color="black" @click.stop="close()">
                            <v-icon>$vuetify.icons.close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-flex ma-0 pa-0 xs12 class="overlayControls ltr" v-show="!isErrored"></v-flex>
            </v-container>
            <v-expand-transition>
                <v-card
                    text
                    v-if="this.$root.mediaPlayerDockedExpand"
                    v-touch:swipe.bottom="toggleCard"
                    elevation="0"
                    :z-index="0"
                    :ripple="false"
                    :style="{ height: 'var(--dock-height)' + 'px' }"
                    class="transition-fast-in-fast-out v-card--reveal rounded-t-xl"
                >
                    <v-card-actions pa-0 ma-0>
                        <v-btn @click="toggleCard" icon class="justify-start">
                            <v-icon color="black">$vuetify.icons.collapse</v-icon>
                        </v-btn>
                        <!-- SHARE -->
                        <v-flex
                            ma-0
                            pa-0
                            xs12
                            shrink
                            class="share overlayControls"
                            style="z-index: 99"
                        >
                            <Share
                                :btnDark="false"
                                mediaType="audio"
                                :item="item"
                                :showDownload="!isLive"
                                :showFavorite="!isLive"
                            />
                        </v-flex>
                    </v-card-actions>
                    <div class="pt-0">
                        <PlayControllerAudio
                            :item="$root.mediaPlayerItem"
                            :dockHeight="dockHeight"
                        />
                    </div>
                </v-card>
            </v-expand-transition>
        </v-card>
    </v-lazy>
</template>

<script>
    import ItemModel from "../models/itemmodel";
    import PlayControllerBase from "./PlayControllerBase";
    import Share from "./Share.vue";
    import PlayControllerAudio from "./PlayControllerAudio";

    export default {
        extends: PlayControllerBase,
        components: {
            Share,
            PlayControllerAudio,
        },

        data: () => {
            return {
                speed: 30000,
                dockHeight: 90,
                restartSlider: 0,
            };
        },
        props: {
            showCloseMinimizeButtons: {
                type: Boolean,
                default: function() {
                    return true;
                },
            },
            item: {
                type: ItemModel,
                default: function() {
                    return new ItemModel();
                },
            },
            itemRoute: {
                type: Object,
                default: function() {
                    return null;
                },
            },
        },
        computed: {
            playableAudio() {
                return this.item != null && this.item.hasAudioAttachment();
            },
        },

        mounted() {
            this.calculateSpeed();
            this.$on("reopen", this.reopen);
            this.calculateHeight();
            window.addEventListener("resize", this.calculateHeight);
        },
        methods: {
            toggleCard() {
                this.$emit(
                    "toggleDockedPlayer",
                    !this.$root.mediaPlayerDockedExpand,
                    this.$root.mediaPlayerDockedExpand
                );
            },
            calculateHeight() {
                const windowHeight =
                    window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight;
                this.dockHeight = windowHeight * 0.95;
                console.log("dockHeight", this.dockHeight);
            },
            //adjust speed based on title length
            calculateSpeed() {
                const averageCharWidth = 10;
                const textLength = this.itemTitle.length;
                const baseSpeed = 30000;
                const calculatedSpeed = textLength * averageCharWidth + baseSpeed;

                this.speed = calculatedSpeed;
            },

            reopen() {
                const card = document.querySelector(".dockedPlayer"); // for future- incorporate the animation with the item update and not just along side it
                if (card) {
                    card.classList.remove("slide-top");
                    card.classList.add("slide-bottom");
                    setTimeout(() => {
                        this.$root.mediaPlayerDocked = false;
                        this.restartSlider += 1;
                        card.classList.remove("slide-bottom");
                        card.classList.add("slide-top");
                        this.$root.mediaPlayerDocked = true;
                    }, 500);
                }
            },
            close() {
                this.$emit("close");
            },
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.calculateHeight);
        },
        watch: {
            $route: function(to, ignoredFrom) {
                if (this.thisRouteName == to.name) {
                    console.log("PlayCControllerAudio Shown");
                    this.loadDefaultItem();
                }
            },

            itemTitle() {
                this.calculateSpeed();
            },
        },
    };
</script>

<style scoped>
    @import url("../assets/css/item-style.css");
    @import url("../assets/css/shared-styles.css");

    .dockedPlayer {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 3;
        transition: 0.3s;
        background-color: #ebebeb;
        bottom: 57px;
        left: 0px;
        width: 100vw;
        border-radius: 18px 18px 0 0;
        transform: translateY(0);
    }

    .dockedPlayer::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 54px;
        height: 100%;
        border-radius: 18px 18px 0 0;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
        z-index: -1;
    }

    .dockedPlayerFullscreen {
        bottom: -1px !important;
        z-index: 30 !important;
    }

    .v-btn {
        min-width: 0;
        padding: 0;
    }

    .v-card--reveal {
        position: fixed;
        bottom: 0;
        right: 0;
        opacity: 1 !important;
        width: 100%;
        overflow: none;
        box-shadow: 0 -3px 8px -2px rgba(125, 125, 125, 0.4) !important;
    }

    .textWrap::-webkit-scrollbar {
        display: none;
    }

    .podimg {
        max-width: 60vw;
        border-radius: 6px;
    }

    .ltr {
        direction: ltr;
    }

    .noscale {
        font-size: var(--v-theme-date-font-size);
    }

    .playerRoot {
        width: 100%;
        height: calc(90vh - 98px);
        z-index: 30;
        transition: 0.3s;
    }

    .player {
        min-height: 0;
        min-width: 0;
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    .overlayControls {
        z-index: 100;
    }

    .itemLiveMarker {
        border-radius: 3px;
        padding: 2px 8px 2px 8px;
        color: white;
        font-family: var(--v-theme-font);
        font-size: calc(0.8 * var(--v-theme-font-size));
        line-height: calc(0.8 * var(--v-theme-line-height));
    }

    .itemLiveMarkerIcon {
        font-size: calc(0.8 * var(--v-theme-font-size)) !important;
        line-height: calc(0.8 * var(--v-theme-line-height)) !important;
        vertical-align: middle;
        padding-bottom: 2px;
    }
</style>

<style>
    .src-components-marqueeSliderContainer-1UFI {
        animation-delay: 2s;
    }

    .hideThumb .v-slider__thumb {
        height: 0 !important;
        width: 0;
    }

    .blurEnds {
        mask-image: linear-gradient(
            to right,
            transparent 0%,
            black 5%,
            black 95%,
            transparent 100%
        );
        -webkit-mask-image: linear-gradient(
            to right,
            transparent 0%,
            black 5%,
            black 95%,
            transparent 100%
        );
    }

    .scrollTitle {
        white-space: nowrap;
        padding: 0 10px;
    }

    .slide-top {
        -webkit-animation: slide-top 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
        animation: slide-top 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
    }

    .slide-bottom {
        -webkit-animation: slide-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) both;
        animation: slide-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    }

    @-webkit-keyframes slide-top {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }

        100% {
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
        }
    }

    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }

        100% {
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
        }
    }

    @-webkit-keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }

    @keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
</style>
