<template>
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
    <g>
      <path
        d="m2.73,10c-.21,0-.41-.06-.59-.18-.34-.23-.55-.62-.55-1.06V1.24c0-.44.2-.83.55-1.06.35-.23.79-.24,1.15-.02l6.13,3.76c.36.22.58.63.58,1.08s-.22.86-.58,1.08l-6.13,3.76c-.17.11-.37.16-.56.16h0Z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "play",
  }
</script>

<style scoped>
  g {
    fill: currentColor !important;
  }
</style>
