<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 10 10"
    xml:space="preserve"
  >
    <g>
      <path
        d="M2.5,10.1c0.7,0,1.3-0.6,1.5-1.3V1.2C3.7,0.5,3.2-0.1,2.5-0.1S1.2,0.5,1,1.2v7.6C1.2,9.5,1.8,10.1,2.5,10.1z M7.5,10.1c0.7,0,1.3-0.6,1.5-1.3V1.2C8.8,0.5,8.2-0.1,7.5-0.1C6.8-0.1,6.3,0.5,6,1.2v7.6C6.3,9.5,6.8,10.1,7.5,10.1z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "pause",
  }
</script>

<style scoped>
  g {
    fill: currentColor !important;
  }
</style>
