<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >

    <g
      id="nav/icons/selected/latest"
      stroke="none"
      stroke-width="1"
      fill="#61c230"
      fill-rule="evenodd"
>
      <use id="Mask-Copy" fill="white" xlink:href="#path-1" />
    <path d="M8.34,3.15A9.34,9.34,0,0,1,12.3.5,9.3,9.3,0,0,0,.49,6.38,9.65,9.65,0,0,0,0,9.4H0v0a6.5,6.5,0,1,0,13,0A6.56,6.56,0,0,0,8.34,3.15Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "logoInverted"
};
</script>

<style scoped>
g path {
  fill: currentColor !important;
}
</style>
