<template>

<svg width="24px" height="24px" viewBox="5 5 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="av-controls/black/play" fill="#000000" fill-rule="nonzero">
            <g id="noun_play_2375486_000000" transform="translate(-0.500000, 0.000000)">
                <!--<path d="M12.5,24 C9.294875,24 6.281,22.751625 4.014875,20.485125 C1.74875,18.218625 0.5,15.205125 0.5,12 C0.5,8.794875 1.748375,5.781 4.014875,3.514875 C6.281375,1.248375 9.294875,0 12.5,0 C15.705125,0 18.719,1.248375 20.985125,3.514875 C23.251625,5.781375 24.5,8.794875 24.5,12 C24.5,15.205125 23.251625,18.219 20.985125,20.485125 C18.718625,22.751625 15.705125,24 12.5,24 Z M12.5,1 C9.56169492,1 6.79938983,2.144 4.72169492,4.22169492 C2.644,6.29938983 1.5,9.06169492 1.5,12 C1.5,14.9383051 2.644,17.7006102 4.72169492,19.7783051 C6.79938983,21.856 9.56169492,23 12.5,23 C15.4383051,23 18.2006102,21.856 20.2783051,19.7783051 C22.356,17.7006102 23.5,14.9383051 23.5,12 C23.5,9.06169492 22.356,6.29938983 20.2783051,4.22169492 C18.2006102,2.144 15.4383051,1 12.5,1 Z" id="Shape"></path>-->
                <path d="M10.4485,16 C10.277,16 10.10585,15.9574977 9.95465,15.873152 C9.66975,15.7143449 9.5,15.4372561 9.5,15.1318324 L9.5,9.86813407 C9.5,9.56271041 9.66975,9.28562163 9.95465,9.12681451 C10.2476,8.96339473 10.6151,8.95746417 10.914,9.11165864 L16.01735,11.7435078 C16.3152,11.8970433 16.5,12.1866522 16.5,12.4996538 C16.5,12.8126554 16.3152,13.1025937 16.01735,13.2557997 L10.914,15.8876489 C10.76945,15.9621103 10.6088,15.999341 10.4485,15.999341 L10.4485,16 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "playNoCircle"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>