<template>
    <div
        :class="{ playerRoot: true }"
        :isLive="$root.mediaPlayerItem && $root.mediaPlayerItem.isLive"
        v-on:playStarted="onPlayStarted($event)"
    >
        <v-container fill-height ma-0 mx-auto pa-0 style="max-width:80vw;">
            <v-layout align-center column ma-0 pa-0 xs12>
                <v-spacer></v-spacer>
                <!-- IMAGE -->
                <v-flex v-if="imageUrl != null" flex="2" class="ma-0 pa-0">
                    <v-img
                        :src="imageUrl"
                        contain
                        @error="$logger.logFetchError(imageUrl)"
                        class="mx-auto pa-0 "
                        style="width: 80%; min-height: 100px; min-width: 100px; height: auto; border-radius: 5%;"
                    />
                </v-flex>
                <!-- ITEM  TEXT -->
                <v-flex ma-0 pa-0 v-if="item != null" class="overlayControls">
                    <v-container fluid grid-list-sm pa-0 pb-4 ma-0>
                        <v-row no-gutters>
                            <v-col
                                @click="itemClicked()"
                                style="overflow-x: hidden"
                                ma-0
                                pt-0
                            >
                                <div>
                                    <div v-if="isLive">
                                        <span class="itemLiveMarker accent">
                                            <v-icon
                                                class="ma-0 pa-0 tiny"
                                                style="padding-bottom: 2px !important"
                                                small
                                                color="white"
                                            >
                                                $vuetify.icons.live</v-icon
                                            >LIVE
                                        </span>
                                    </div>
                                    <DateView
                                        v-else
                                        class="date verticalCenter"
                                        :date="item.pubDate"
                                    />
                                </div>
                                <div class="smallHeadline smallHeadline3lines">
                                    {{ itemTitle }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!-- SLIDER -->

                    <v-container
                        ma-0
                        pa-0
                        mx-auto
                        class="overlayControls ltr"
                        style="max-width:80vw; width: 100%;"
                        v-show="!isErrored"
                        fluid
                    >
                        <v-layout align-center justify-center row pa-0 ma-0>
                            <v-flex xs12 class="text-center">
                                <v-slider
                                    ref="slider"
                                    height="4px"
                                    hide-details
                                    color="#627568"
                                    class="progress my-2 pa-0"
                                    background-color="#f0f6f0"
                                    track-color="#f0f6f0"
                                    track-fill-color="#627568"
                                    v-bind="{
                                        value:
                                            $root.mediaPlayer == null
                                                ? 0
                                                : $root.mediaPlayer
                                                      .currentPlayPercentage,
                                    }"
                                    :readonly="isLive"
                                    v-on:input="
                                        if (draggingSlider) {
                                            $root.mediaPlayer.currentPlayPercentage = $event;
                                        }
                                    "
                                    v-on:change="
                                        $root.mediaPlayer.seekToPercentage(
                                            $event
                                        )
                                    "
                                    v-on:start="startDraggingSlider"
                                    v-on:end="stopDraggingSlider"
                                    step="0"
                                    max="10000"
                                />
                            </v-flex>
                            <v-flex xs12>
                                <v-row>
                                    <v-col
                                        class="text-left mediaDurationText secondary--text"
                                    >
                                        {{
                                            (draggingSlider
                                                ? $root.mediaPlayer
                                                      .timeFromPercentage
                                                : $root.mediaPlayer
                                                      .currentPlaySeconds)
                                                | timeInColonFormat
                                        }}
                                    </v-col>
                                    <v-col
                                        class="text-right mediaDurationText secondary--text"
                                        >-{{
                                            $root.mediaPlayer.duration
                                                | timeInColonFormat
                                        }}
                                    </v-col>
                                </v-row>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
                <!-- REPLAY, PLAY AND SKIP -->
                <v-flex ma-0 px-2 flex="4" class="overlayControls ltr">
                    <v-container fluid fill-height pa-0 ma-0>
                        <v-row
                            class="ma-0 mx-auto pa-0 flex-nowrap"
                            align="center"
                            justify="space-around"
                            style="max-width: 250px; min-height:100px"
                        >
                            <div
                                v-if="isErrored"
                                class="storyText"
                                style="color: black"
                            >
                                {{ $t("media_failed_to_load") }}
                            </div>
                            <v-btn
                                v-if="!isLive && !isErrored"
                                text
                                icon
                                color="black"
                                @click.stop="$root.mediaPlayer.replay10()"
                                class="ma-0 pa-0 large-button"
                            >
                                <v-icon size="35"
                                    >$vuetify.icons.replay10</v-icon
                                >
                            </v-btn>
                            <v-btn
                                v-if="
                                    !$root.mediaPlayer.isPlaying && !isErrored
                                "
                                text
                                icon
                                color="black"
                                @click.stop="$root.mediaPlayer.play()"
                                class="ma-1 pa-0 x-large-button"
                            >
                                <v-icon size="44px"
                                    >$vuetify.icons.play
                                </v-icon>
                            </v-btn>
                            <v-btn
                                x-large
                                v-if="$root.mediaPlayer.isPlaying && !isErrored"
                                text
                                icon
                                color="black"
                                @click.stop="$root.mediaPlayer.pause()"
                                class="ma-1 pa-0 x-large-button"
                            >
                                <v-icon size="44px"
                                    >$vuetify.icons.pause
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="!isLive && !isErrored"
                                text
                                icon
                                color="black"
                                @click.stop="$root.mediaPlayer.forward10()"
                                class="ma-0 pa-0 large-button"
                            >
                                <v-icon size="35"
                                    >$vuetify.icons.forward10</v-icon
                                >
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-flex>
                <v-spacer></v-spacer>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import PlayControllerBase from "./PlayControllerBase";
    import DateView from "./DateView";

    export default {
        extends: PlayControllerBase,
        components: {
            DateView,
        },
        props: {
            showCloseMinimizeButtons: {
                type: Boolean,
                default: function() {
                    return true;
                },
            },
        },
        data: () => ({
            showMore: false,
        }),

        methods: {
            playChild(event) {
                event.stopPropagation();
            },
        },
        mounted() {
            // Hardcode slider to LTR
            this.$refs.slider.$vuetify = {};
        },
        computed: {
            itemDescription() {
                if (this.item != null) {
                    return this.item.description;
                }
                return "";
            },
            playableAudio() {
                return this.item != null && this.item.hasAudioAttachment();
            },
            playableVideo() {
                return this.item != null && this.item.hasVideoAttachment();
            },
        },
        watch: {
            $route: function(to, ignoredFrom) {
                if (this.thisRouteName == to.name) {
                    console.log("PlayCControllerAudio Shown");
                    this.loadDefaultItem();
                }
            },
        },
    };
</script>

<style scoped>
    .ltr {
        direction: ltr;
    }

    .noscale {
        font-size: var(--v-theme-font-size);
    }

    .playerRoot {
        width: 100%;
        z-index: 30;
        transition: 0.3s;
    }

    .playerRoot.showMore {
        height: auto;
        overflow-y: auto;
    }

    .player {
        min-height: 0;
        min-width: 0;
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    .overlayControls {
        z-index: 100;
    }

    .v-btn {
        min-width: 0;
        padding: 0;
    }

    .image {
        width: 210px;
        height: 210px;
        object-fit: contain;
        border-radius: 2px;
    }

    .itemLiveMarker {
        border-radius: 3px;
        padding: 2px 8px 2px 8px;
        color: white;
        font-family: var(--v-theme-font);
        font-size: calc(0.8 * var(--v-theme-font-size));
        line-height: calc(0.8 * var(--v-theme-line-height));
    }

    .itemLiveMarkerIcon {
        font-size: calc(0.8 * var(--v-theme-font-size)) !important;
        line-height: calc(0.8 * var(--v-theme-line-height)) !important;
        vertical-align: middle;
        padding-bottom: 2px;
    }
</style>
