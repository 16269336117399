<template>
  <svg
    width="11px"
    height="10px"
    viewBox="0 0 11 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Home" transform="translate(-116.000000, -630.000000)">
        <g id="media/listen" transform="translate(116.000000, 629.000000)" fill="#949494">
          <g id="noun_Audio_830722_000000" transform="translate(0.000000, 1.500000)">
            <path
              d="M5.99441783,0.0675385146 L2.5411119,2.81760586 C2.47595519,2.86613646 2.41079847,2.88231333 2.34564176,2.88231333 L0.325783578,2.88231333 C0.14660261,2.88231333 0,3.02790513 0,3.20585066 L0,5.79414934 C0,5.97209487 0.14660261,6.11768667 0.325783578,6.11768667 L2.32935258,6.11768667 C2.41079847,6.11768667 2.47595519,6.15004041 2.52482273,6.18239414 L5.97812865,8.93246149 C6.18988797,9.09423015 6.49938237,8.94863835 6.49938237,8.67363162 L6.49938237,0.326368382 C6.51567155,0.0513616479 6.20617715,-0.0942301528 5.99441783,0.0675385146 Z"
              id="Path"
              fill-rule="nonzero"
            ></path>
            <path
              d="M7.9047619,2.08333333 C7.80952381,1.97222222 7.66666667,1.97222222 7.57142857,2.08333333 C7.47619048,2.19444444 7.47619048,2.36111111 7.57142857,2.47222222 C7.86904762,2.81944444 8.02380952,3.26388889 8.02380952,3.75 C8.02380952,4.23611111 7.85714286,4.68055556 7.57142857,5.02777778 C7.47619048,5.13888889 7.47619048,5.30555556 7.57142857,5.41666667 C7.61904762,5.47222222 7.67857143,5.5 7.73809524,5.5 C7.79761905,5.5 7.85714286,5.47222222 7.9047619,5.41666667 C8.28571429,4.97222222 8.5,4.375 8.5,3.75 C8.5,3.125 8.28571429,2.52777778 7.9047619,2.08333333 Z"
              id="Path"
              fill-rule="nonzero"
            ></path>
            <path
              d="M9.64150943,1.09217877 C9.49056604,0.969273743 9.26415094,0.969273743 9.11320755,1.09217877 C8.96226415,1.2150838 8.96226415,1.39944134 9.11320755,1.52234637 C9.8490566,2.12150838 10.245283,2.90502793 10.245283,3.75 C10.245283,4.59497207 9.8490566,5.37849162 9.11320755,5.97765363 C8.96226415,6.10055866 8.96226415,6.2849162 9.11320755,6.40782123 C9.18867925,6.46927374 9.28301887,6.5 9.37735849,6.5 C9.47169811,6.5 9.56603774,6.46927374 9.64150943,6.40782123 C10.5283019,5.70111732 11,4.74860335 11,3.75 C11,2.75139665 10.5283019,1.79888268 9.64150943,1.09217877 Z"
              id="Path"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "typeAudio"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>