// eslint-disable-next-line
import ItemModel from "./models/itemmodel";

/**
 * Base class for logging.
 */
export default class Logger {
    logStateOnboardingScreen() { }
    logPageView(ignoredPageName) { }
    logHeaderTagSelected(ignoredTag) { }
    logArticleView(ignoredItem) { }
    logVideoListView() { }
    logAudioListView() { }
    logAppFirstLoad() { }
    logAppLoad() { }
    logAppExit() { }
    logAppError() { }
    logAppUpdated() { }
    logLanguageSelect(ignoredLanguage, ignoredFromSettings) { }
    logFetchError(ignoredUrl) { }

    /**
     * 
     * @param {ItemModel} item  The item
     * @param {String} type  Type of item, one of "article", "video" or "audio"
     */
    logArticleFavorited(item, type) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: article favorited", item, type);
        }
    }

    /**
     * 
     * @param {ItemModel} item  The item
     * @param {String} type  Type of item, one of "article", "video" or "audio"
     */
    logArticleDownload(item, type) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: article download", item, type);
        }
    }

    /**
     * 
     * @param {ItemModel} item  The item
     * @param {String} type  Type of item, one of "article", "video" or "audio"
     */
    logArticleShare(item, type) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: article share", item, type);
        }
    }

        
    logTextSizeSelect(newSize) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: text size set", newSize);
        }
    }

    logClosePlayer(item, type) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: close player", item, type);
        }
    }

    logNavigation(pageName) { 
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: navigation", pageName);
        }
    }

    /**
     * Media has been played.
     * @param {ItemModel} item  The item
     */
    logMediaPlay(item) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: media play", item);
        }
    }

    /**
     * Media has been paused.
     * @param {ItemModel} item  The item
     */
    logMediaPause(item) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: media pause", item);
        }
    }

    /**
     * Media has been played to the end.
     * @param {ItemModel} item  The item
     */
    logMediaComplete(item) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: media complete", item);
        }
    }

    /**
     * Media has been played past a media milestone.
     * @param {ItemModel} item  The item
     * @param {number} percent The media milestone we passed, in percent. Currently valid values are 10, 50 and 90.
     */
    logMediaMilestone(item, percent) {
        if (process.env.NODE_ENV === 'development') {
            console.log("LOG: media milestone", item, percent);
        }
    }
}
