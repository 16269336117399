
<template>

<svg  viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g >
        <path id="Path" class="cls-1" d="m9.67,8.48c-.46,0-.88.18-1.2.46l-4.36-2.5c.03-.14.05-.28.05-.42s-.02-.28-.05-.42l4.31-2.48c.33.3.76.49,1.25.49,1.01,0,1.83-.81,1.83-1.81s-.82-1.81-1.83-1.81-1.83.81-1.83,1.81c0,.14.02.28.06.42l-4.31,2.48c-.33-.3-.76-.49-1.25-.49-1.01,0-1.83.81-1.83,1.81s.82,1.81,1.83,1.81c.48,0,.92-.19,1.25-.49l4.35,2.51c-.03.13-.05.26-.05.39,0,.97.8,1.76,1.78,1.76s1.78-.79,1.78-1.76-.8-1.76-1.78-1.76Z"/>
    </g>
</svg>
</template>

<script>
export default {
  name: "share"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>