import Vue from 'vue'
import Router from 'vue-router'
import Main from './views/Main.vue'
import store from './store'
import services from './config';

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: Main,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/Home.vue'),
          props: true,
          meta: { tab: 0 }
        },
        {
          path: '/categories',
          name: 'categories',
          component: () => import('./views/Home.vue'),
          props: { headerType: 'categories' },
          meta: { tab: 1 }
        },
        {
          path: '/radio',
          name: 'radio',
          component: () => import('./views/Radio.vue'),
          props: true,
          meta: { tab: 2 }
        },
        {
          path: '/video',
          name: 'video',
          component: () => import('./views/Video.vue'),
          props: true,
          meta: { tab: 3 }
        },
        {
          path: '/saved',
          name: 'saved',
          component: () => import('./views/Home.vue'),
          props: { headerType: 'saved' },
          meta: { tab: 4 }
        },
        {
          path: '/more',
          name: 'more',
          component: () => import('./views/More.vue'),
          props: true,
          meta: { tab: 5 }
        }
      ]
    },
    {
      path: '/service/:name?',
      redirect: to => {
        const name = to.params.name;
        if (name) {
          const service = services[name];
          if (service) {
            console.log("Selecting service by param:", name);
            store.commit("setFlavor", name);
            store.state.onboarded = true;

            if ('static' in to.query) {
              console.log("Service selection is static!");
              store.commit("setFlavorStatic", true);
            }
            let services = to.query["services"];
            if (services) {
              console.log("Service selection is limited to:", services);
              store.commit("setFlavorsAvailable", services);
            }
          }
        }
        return '/';
      }
    },
    {
      path: "/services/:services?",
      redirect: (to) => {
        let services = to.params.services;
        if (services) {
          console.log("Service selection is limited to:", services);
          store.commit("setFlavorsAvailable", services);
        }
        if (store.state.onboarded) {
          return "/";
        } else {
          return "/onboarding";
        }
      },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('./views/Onboarding.vue')
    }
  ]
})
