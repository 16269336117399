<template>
<svg width="29px" height="29px" viewBox="5 5 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dock/audio" transform="translate(-262.000000, -17.000000)" fill="#000000">
            <g id="av-controls/black/pause" transform="translate(262.000000, 17.025674)">
                <!--<g id="noun_play_2375486_000000" stroke-width="1">
                    <path d="M14.5,29 C10.6271406,29 6.985375,27.4915469 4.24714063,24.7528594 C1.50890625,22.0141719 0,18.3728594 0,14.5 C0,10.6271406 1.50845313,6.985375 4.24714063,4.24714063 C6.98582813,1.50845312 10.6271406,0 14.5,0 C18.3728594,0 22.014625,1.50845313 24.7528594,4.24714063 C27.4915469,6.98582813 29,10.6271406 29,14.5 C29,18.3728594 27.4915469,22.014625 24.7528594,24.7528594 C22.0141719,27.4915469 18.3728594,29 14.5,29 Z M14.5,1.20833333 C10.949548,1.20833333 7.61176271,2.59066667 5.10121469,5.10121469 C2.59066667,7.61176271 1.20833333,10.949548 1.20833333,14.5 C1.20833333,18.050452 2.59066667,21.3882373 5.10121469,23.8987853 C7.61176271,26.4093333 10.949548,27.7916667 14.5,27.7916667 C18.050452,27.7916667 21.3882373,26.4093333 23.8987853,23.8987853 C26.4093333,21.3882373 27.7916667,18.050452 27.7916667,14.5 C27.7916667,10.949548 26.4093333,7.61176271 23.8987853,5.10121469 C21.3882373,2.59066667 18.050452,1.20833333 14.5,1.20833333 Z" id="Shape" fill-rule="nonzero"></path>
                </g>-->
                <path d="M12.0833333,19.3333333 C12.7506774,19.3333333 13.2916667,18.7923441 13.2916667,18.125 L13.2916667,10.875 C13.2916667,10.2076559 12.7506774,9.66666667 12.0833333,9.66666667 C11.4159893,9.66666667 10.875,10.2076559 10.875,10.875 L10.875,18.125 C10.875,18.7923441 11.4159893,19.3333333 12.0833333,19.3333333 Z" id="Path"></path>
                <path d="M16.9166667,19.3333333 C17.5840107,19.3333333 18.125,18.7923441 18.125,18.125 L18.125,10.875 C18.125,10.2076559 17.5840107,9.66666667 16.9166667,9.66666667 C16.2493226,9.66666667 15.7083333,10.2076559 15.7083333,10.875 L15.7083333,18.125 C15.7083333,18.7923441 16.2493226,19.3333333 16.9166667,19.3333333 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "pauseNoCircle"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>