<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 10 10"
    xml:space="preserve"
  >
    <g>
      <path
        d="M5.9,5l3.9,3.9c0.2,0.2,0.2,0.7,0,0.9s-0.7,0.2-0.9,0L5,5.9L1.1,9.8
	c-0.2,0.2-0.7,0.2-0.9,0s-0.2-0.7,0-0.9L4.1,5L0.2,1.1c-0.2-0.2-0.2-0.7,0-0.9s0.7-0.2,0.9,0L5,4.1l3.9-3.9c0.2-0.2,0.7-0.2,0.9,0
	s0.2,0.7,0,0.9L5.9,5z"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "close",
  }
</script>

<style scoped>
  g {
    fill: currentColor !important;
  }
</style>
