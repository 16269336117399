<template>
    <div>
        <div
            :class="{
                contentView: true,
                contentViewOverflowing: $store.state.fullScreenItems != null,
            }"
            :style="{zIndex: $store.state.fullScreenItems ? 4 : 3}"
        >
            <transition :name="transitionName" appear>
                <keep-alive>
                    <router-view :key="'view_' + $route.name" />
                </keep-alive>
            </transition>
        </div>

        <DockedPlayer
            v-if="$root.mediaPlayerDocked"
            :item="$root.mediaPlayerItem"
            :itemRoute="$root.mediaPlayerItemRoute"
            :expanded="$root.mediaPlayerDockedExpand"
            @toggleDockedPlayer="handleExpandDock"
            v-on:close="onCloseDockedPlayer()"
        />

        <SharedMediaPlayer :item="$root.mediaPlayerItem" />

        <v-bottom-navigation
            fixed
            height="58px"
            width="100%"
            :style="{zIndex: this.$store.state.showingFullScreenVideo ? 2 : 3}"
            class="navigationBar text-center"
            color="#fff"
            mandatory
            grow
        >
            <v-btn
                :ripple="false"
                v-blur
                exact
                class="navButton"
                :to="{name: 'home'}"
                @click="logNav('home')"
            >
                <v-icon size="26"> $vuetify.icons.home</v-icon>
            </v-btn>
            <v-btn
                :ripple="false"
                v-blur
                class="navButton"
                to="categories"
                @click="logNav('categories')"
            >
                <v-icon size="26">$vuetify.icons.categories</v-icon>
            </v-btn>
            <v-btn
        :ripple="false"
        v-blur
        class="navButton"
        to="radio"
        @click="logNav('radio')"
      >
                <v-icon :class="{liveRadioIcon: liveRadioAvailable}" size="26"
                    >$vuetify.icons.radio</v-icon
                >
                <span v-if="liveRadioAvailable" class="notificationIcon"></span>
            </v-btn>
            <v-btn
        :ripple="false"
        v-blur
        class="navButton"
        to="video"
        @click="logNav('video')"
      >
                <v-icon size="28">$vuetify.icons.typeVideo</v-icon>
            </v-btn>
            <v-btn
        :ripple="false"
        v-blur
        class="navButton"
        to="saved"
        @click="logNav('saved')"
      >
                <v-icon size="26">$vuetify.icons.favorites</v-icon>
            </v-btn>
            <v-btn
        :ripple="false"
        v-blur
        class="navButton"
        to="more"
        @click="logNav('more')"
      >
                <v-icon size="26">$vuetify.icons.more</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
import SharedMediaPlayer from "../components/SharedMediaPlayer"
import DockedPlayer from "../components/DockedPlayer"

  export default {
    name: "Main",
    components: {
        SharedMediaPlayer,
        DockedPlayer,
    },
    data() {
        return {
            transitionName: null,
        }
    },
    computed: {
        liveRadioAvailable() {
            return this.$store.state.liveRadio != null
        },
    },

    methods: {
        logNav(page) {
            this.$logger.logNavigation(page)
        },

        onCloseDockedPlayer() {
            console.log("onCloseDockedPlayer")
            this.$logger.logClosePlayer(
                this.$root.mediaPlayerItem,
                this.$root.mediaPlayerItem != null &&
                    this.$root.mediaPlayerItem.hasVideoAttachment()
                    ? "video"
                    : "audio"
            )
            const card = document.querySelector(".dockedPlayer")
            if (card) {
                card.classList.remove("slide-top")
                card.classList.add("slide-bottom")
            } else card.classList.add("slide-top")

            setTimeout(() => {
                this.$root.clearMediaItem()
                this.$root.mediaPlayerDocked = false
            }, 200)

            if (this.expanded != null) {
                this.$store.commit("setFullScreenItems", {
                    items: null,
                    activeIndex: -1,
                })
            }
        },
        handleExpandDock(expanded) {
            this.$root.mediaPlayerDockedExpand = expanded
        },
    },
    watch: {
        $route(to, from) {
            if (this.$root.mediaPlayerDocked) {
                const itemRouteName = "audio"
                if (to.name !== itemRouteName) {
                    this.$root.mediaPlayerDockedExpand = false
                }
            }
            const toDepth = to.meta.tab || 0
            const fromDepth = from.meta.tab || 0
            this.transitionName = toDepth < fromDepth ? "slide-left" : "slide-right"
        },
    },
}
</script>

<style scoped>
  @import url("../assets/css/item-style.css");
  @import url("../assets/css/shared-styles.css");

  .contentView {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 58px;
    right: 0px;
    overflow-y: hidden;
  }

  /* When doing full screen video, use whole screen */
  .fullscreenVideo .contentView {
    bottom: 0px;
  }

  .contentViewOverflowing {
    overflow-y: visible !important;
  }

  .navigationBar {
    background-color: #fff;
    margin-bottom: env(safe-area-inset-bottom )!important;
}

  .navButton {
    min-width: 10% !important;
  }

  .navButton.v-btn--active {
    color: #000 !important;
  }

  .notificationIcon {
    display: inline-block;
    position: absolute;
    bottom: calc(50% + 8px);
    left: calc(50% + 8px);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--v-accent-bright-green);
  }

  .notificationIcon::after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    border: 2px solid var(--v-accent-bright-green);
    opacity: 0.5;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: 10s sonar 1s 3;
  }

  @keyframes sonar {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    10% {
      transform: scale(2.5);
      opacity: 0;
    }

    19% {
      transform: scale(1);
      opacity: 0;
    }

    20% {
      transform: scale(1);
      opacity: 1;
    }

    30% {
      transform: scale(2.5);
      opacity: 0;
    }

    39% {
      transform: scale(1);
      opacity: 0;
    }

    40% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(2.5);
      opacity: 0;
    }

    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  .liveRadioIcon {
    animation: 10s shake 1s 3;
  }

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }

    2.5% {
      transform: rotate(21deg);
    }

    5% {
      transform: rotate(-18deg);
    }

    7.5% {
      transform: rotate(15deg);
    }

    10% {
      transform: rotate(-12deg);
    }

    12.5% {
      transform: rotate(9deg);
    }

    15% {
      transform: rotate(-6deg);
    }

    17.5% {
      transform: rotate(3deg);
    }

    20.0% {
      transform: rotate(0deg);
    }

    40% {
      transform: rotate(0deg);
    }

    42.5% {
      transform: rotate(21deg);
    }

    45% {
      transform: rotate(-18deg);
    }

    47.5% {
      transform: rotate(15deg);
    }

    50% {
      transform: rotate(-12deg);
    }

    52.5% {
      transform: rotate(9deg);
    }

    55% {
      transform: rotate(-6deg);
    }

    57.5% {
      transform: rotate(3deg);
    }

    60.0% {
      transform: rotate(0deg);
    }

    100.0% {
      transform: rotate(0deg);
    }
  }
</style>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-to {
    transform: translate(-100%, 0);
  }

  .slide-left-leave-to,
  .slide-right-enter {
    transform: translate(100%, 0);
  }

  .v-item-group.v-bottom-navigation {
    box-shadow: none;
  }
</style>
