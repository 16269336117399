import moment from "moment";

export default class RadioParser {

    static parseSchedule(url, lang, callback) {
        const self = this;
        const options = {
            method: "get",
            url: url
            //headers: {common: {'x-destination': 'schedule'}}
          };
        window.axios(options)
            .then(function (response) {
                let result = self.parse(self, lang, response.data, url);
                callback(result);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    static parse(self, lang, data, ignoredUrl) {
        // Get the parseString function
        var parseString = require('xml2js').parseString;

        var parseResult = null;
        parseString(data, { explicitArray: true }, function (err, result) {
            var root = result && result["broadcast_schedule"];
            parseResult = self.parseData(self, lang.toUpperCase(), root);
        });
        console.log(parseResult);
        return parseResult;
    }

    static getText = function (elt) {
        if (typeof (elt) === 'string') return elt;
        if (typeof (elt) === 'object' && Object.prototype.hasOwnProperty.call(elt, '_')) return elt._;
        return null;
    }

    static parseData(self, lang, root) {
        var ret = [];
        try {
            var services = root["rfaba:service"];
            for (var index = 0; index < services.length; index++) {
                var service = services[index];
                if (service["rfaba:service_name"][0].toUpperCase() === lang) {
                    var broadcasts = service["rfaba:broadcast"];
                    for (var idxBroadcast = 0; idxBroadcast < broadcasts.length; idxBroadcast++) {
                        var broadcast = broadcasts[idxBroadcast];
                        var dayOfWeek = broadcast["rfaba:day_of_week"][0];
                        var start = moment.utc(broadcast["rfaba:start_time"][0], "H:mm", true).local();
                        var duration = moment.duration({minutes: broadcast["rfaba:duration"][0]});
                        var url = broadcast["rfaba:url"][0];
                        ret.push({dayOfWeek: dayOfWeek, start: start, duration: duration, url: url});
                    }
                    break;
                }
            }
        }
        catch (e) {
            console.log("Error parsing radio schedule: " + e);
        }
        return ret;
    }
}