<template>

<svg width="16px" height="7px" viewBox="0 0 16 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="nav/icons/left/black/collapse" transform="translate(-4.000000, -8.000000)" fill="#000000" fill-rule="nonzero">
            <path d="M12,10.198921 L18.9473427,14.8959656 C19.3065926,15.1388517 19.743437,14.9419536 19.923062,14.4561814 C20.102687,13.9704092 19.9570722,13.3797149 19.5978222,13.1368288 L12,8 L4.40217778,13.1368288 C4.04292785,13.3797149 3.89731304,13.9704092 4.07693801,14.4561814 C4.25656297,14.9419536 4.69340739,15.1388517 5.05265733,14.8959656 L12,10.198921 Z" id="Path-2" transform="translate(12.000000, 11.500000) rotate(-180.000000) translate(-12.000000, -11.500000) "></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "collapse"
};
</script>

<style scoped>
g {
    fill: currentColor !important;
}
</style>